import React, { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { message } from "antd";
import { API, STRAPI_API, BEARER } from "../../constant";
import { useEffect } from "react";
import { getToken, removeToken, getExpire } from "../../helpers";

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [activeSubscription, setActiveSubscription] = useState();
  const [availableSubscriptions, setAvailableSubscriptions ] = useState();
  const [subPending, setSubPending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const authToken = getToken();
  const expire = getExpire();

  const fetchLoggedInUser = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/users/me`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const data = await response.json();

      setUserData(data);
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (user) => {
    setUserData(user);
  };

  const handleSub = (sub) => {
    setActiveSubscription(sub);
  };

  useEffect(() => {
    if (authToken && new Date().getTime() < new Date(getExpire()).getTime()) {
        fetchLoggedInUser(authToken);
    } else {
        removeToken();
        setUserData(null);
    }
  }, [authToken]);

  return (
    <AuthContext.Provider
      value={{ user: userData, sub: activeSubscription, availableSubs: availableSubscriptions, setUser: handleUser, setSub: handleSub, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
