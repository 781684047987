import React, { Component } from 'react';
import { render } from 'react-dom'
import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';
//import Map from 'react-map-gl';
//import 'mapbox-gl/dist/mapbox-gl.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../Styles/background.css';

function MapPlaceholder() {
  return (
    <p>
      Map of London.{' '}
      <noscript>You need to enable JavaScript to see this map.</noscript>
    </p>
  )
}

class BackgroundCaraousel extends React.Component {
  constructor() {
    super()
    this.state = {
      markers: [],
      lat: 51.505,
      lng: 10.45,
      zoom: 13,
      map: null,
      leafletInitialized: false,
      leafletMarkersInitialized: false
    }
  }

  componentDidUpdate(prevProps) {
    const atHersteller = (this.props.location.pathname.indexOf("/hersteller") > -1 );
    const atVeranstaltungen =  (this.props.location.pathname.indexOf("/veranstaltungen") > -1 );
    const atReferenten = (this.props.location.pathname.indexOf("/referenten") > -1 );
    const atVersorgungspartner = (this.props.location.pathname.indexOf("/versorgungspartner") > -1 );

    if (atHersteller) return;

    const prevVerChanged = (( ((prevProps.location.pathname.indexOf("/veranstaltungen/") > -1 ) && (this.props.location.pathname.indexOf("/veranstaltungen/") === -1 ))
     || ((prevProps.location.pathname.indexOf("/veranstaltungen/") > -1 ) && (this.props.location.pathname.indexOf("/veranstaltungen") === -1 ))
     || ((prevProps.location.pathname.indexOf("/veranstaltungen/") === -1 ) && (this.props.location.pathname.indexOf("/veranstaltungen/") > -1 ))
      )
      ||
      ( ((prevProps.location.pathname.indexOf("/versorgungspartner/") > -1 ) && (this.props.location.pathname.indexOf("/versorgungspartner/") === -1 ))
           || ((prevProps.location.pathname.indexOf("/versorgungspartner/") > -1 ) && (this.props.location.pathname.indexOf("/versorgungspartner") === -1 ))
           || ((prevProps.location.pathname.indexOf("/versorgungspartner/") === -1 ) && (this.props.location.pathname.indexOf("/versorgungspartner/") > -1 ))
            )
      ||
      ( ((prevProps.location.pathname.indexOf("/referenten/") > -1 ) && (this.props.location.pathname.indexOf("/referenten/") === -1 ))
           || ((prevProps.location.pathname.indexOf("/referenten/") > -1 ) && (this.props.location.pathname.indexOf("/referenten") === -1 ))
           || ((prevProps.location.pathname.indexOf("/referenten/") === -1 ) && (this.props.location.pathname.indexOf("/referenten/") > -1 ))
            )
            ||
            ((prevProps.location.pathname.indexOf("/veranstaltungen") > -1 ) && (this.props.location.pathname.indexOf("/referenten") > -1 ))
            ||
            ((prevProps.location.pathname.indexOf("/referenten") > -1 ) && (this.props.location.pathname.indexOf("/veranstaltungen") > -1 ))

            || ((prevProps.location.pathname.indexOf("/produkt") > -1 || prevProps.location.pathname.indexOf("/hersteller") > -1 )
            && (atVeranstaltungen || atReferenten || atVersorgungspartner)
            )
      );


    if ((atVeranstaltungen || atReferenten || atVersorgungspartner) && !this.state.leafletInitialized) {
        this.initMap();
    } else if (prevVerChanged || ((atVeranstaltungen || atReferenten || atVersorgungspartner) && !this.state.leafletMarkersInitialized)) {
        this.initMarkers();
    } else if (!(atVeranstaltungen || atReferenten || atVersorgungspartner) && this.state.leafletInitialized) {
        this.setState({leafletInitialized: false, leafletMarkersInitialized: false });
    }
  }

  initMarkers() {
    const { veranstaltungenData, history } = this.props;

    const atAdmin = (this.props.history.location.pathname.indexOf("/admin") > -1 );

    if (veranstaltungenData.length === 0 || atAdmin) return;


    const atHome =  (this.props.history.location.pathname === "/")
    || this.props.history.location.pathname.indexOf("/hersteller") > -1
    || this.props.history.location.pathname.indexOf("/info") > -1
    || this.props.history.location.pathname.indexOf("/anmelden") > -1
    || this.props.history.location.pathname.indexOf("/hersteller") > -1
    || this.props.history.location.pathname.indexOf("/produkt") > -1;

    if (atHome) return;

    const atVeranstaltungen = (this.props.history.location.pathname.indexOf("/veranstaltungen") > -1 );
    const atReferenten = (this.props.history.location.pathname.indexOf("/referenten") > -1 );
    const atHersteller = (this.props.history.location.pathname === "/hersteller");
    const atVersorgungspartner = (this.props.history.location.pathname.indexOf("/versorgungspartner") > -1 );


    const filteredVeranstaltungenData = atVeranstaltungen ? veranstaltungenData.filter(({type}) => type === "Veranstaltung") : atVersorgungspartner ? veranstaltungenData.filter(({type}) => type === "Versorgungspartner") : atReferenten ? veranstaltungenData.filter(({type}) => type === "Referent") : atHersteller ? veranstaltungenData.filter(({type}) => type === "Hersteller") : veranstaltungenData;

    const match = history.location.pathname.match(/\/veranstaltungen\/(\d+)/) ||  history.location.pathname.match(/\/referenten\/(\d+)/) ||  history.location.pathname.match(/\/versorgungspartner\/(\d+)/);

    let veranstaltung = [];

    if (match) {
      const vId = match[1];
      veranstaltung = filteredVeranstaltungenData.filter(({id}) => id === +vId);
    }

    this.setState({leafletMarkersInitialized: true});
    const { L } = window;
    var LeafIcon = L.Icon.extend({
        options: {
            iconSize:     [42/2, 46/2],
        }
    });

    if (this.state.markers.length > 0) {
        for(var i = 0; i < this.state.markers.length; i++){
            this.state.map.removeLayer(this.state.markers[i]);
        }
    }

     var markers = [];

     (veranstaltung.length > 0 ? veranstaltung : filteredVeranstaltungenData).filter(
     ({ coordinates, Veranstaltungsort, Einsatzort }) => {
      return typeof coordinates !== "undefined" || typeof Veranstaltungsort !== "undefined" || typeof Einsatzort !== "undefined" }).forEach((
      {id, label, name, Einsatzgebiet, Veranstaltungsname, Vorname, Name, Stadt, Qualifikation, coordinates, Einsatzort, Veranstaltungsort, Bild })  => {
     var greenIcon = new LeafIcon({iconUrl: '/ICW_Logo_mobile.png'}),
         redIcon = new LeafIcon({iconUrl: '/ICW_Logo_mobile.png'}),
         orangeIcon = new LeafIcon({iconUrl: '/ICW_Logo_mobile.png'});

          const markerCoordinates = coordinates || Veranstaltungsort || Einsatzort;
          if ((markerCoordinates || []).length == 2) {
              if (match && typeof Einsatzgebiet === "string" && Einsatzgebiet.match(/\d+$/)) markers.push(L.circle(markerCoordinates, (Einsatzgebiet.match(/\d+$/)[1] || (Einsatzgebiet.match(/\d+$/)[0] * 2)) * 1000).addTo(this.state.map));
              var marker = L.marker(markerCoordinates, {icon: greenIcon}).addTo(this.state.map)

              if (atVeranstaltungen) {
              marker.bindPopup(`${Veranstaltungsname} | ${Stadt}`, {closeOnClick: false, autoClose: false});
    //            marker.openPopup();
              } else if (atReferenten) {
                marker.bindPopup(`${Vorname} | ${Name} (${Stadt})`, {closeOnClick: false, autoClose: false});
              } else if (atVersorgungspartner) {
                marker.bindPopup(`${Name} | (${Stadt})`, {closeOnClick: false, autoClose: false});
              }

              marker.on('mouseover', (e) => {
                              marker.openPopup();
                          });
              marker.on('mouseout', (e) => {
                              marker.closePopup();
                          });

              // add click event to popup
                marker.on('click', (e) => {
                    console.log(e);
                    marker.openPopup();
                    history.push(`/${atReferenten ? 'referenten' : atVersorgungspartner ? 'versorgungspartner' : 'veranstaltungen'}/${id}`);
                });

              markers.push(marker);
          }
     })

    if (markers.length > 0) {
         var group = new L.featureGroup(markers);
         const bounds = group.getBounds();
         if (bounds.isValid()) {
            this.state.map.flyToBounds(group.getBounds(), { duration: 2 } );
         }
    }

     this.setState({ markers });
  }

  initMap() {
      this.setState({leafletInitialized: true});

      // check if a dom with a class 'leaflet-container' already exists

      if (document.getElementsByClassName('leaflet-container').length > 0) {
        return;
      }

      const { L } = window;
      const map =  L.map('leafletMap', {
  dragging: !L.Browser.mobile, attributionControl: false }).setView([this.state.lat, this.state.lng], 6);
      map.scrollWheelZoom.disable();

      this.setState({ map });

      // Add a tile layer to the map from OpenStreetMap
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      // Create a marker with popup and add it to the map

    // get request /dummyVeranstaltungen.json and load them in markerCoords state
  }

  render() {
   const position = [this.state.lat, this.state.lng];

    var settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000
    };
    const { history, backgroundImagesData } = this.props;

    const atHome = false;// (history.location.pathname === "/");
    const atVeranstaltungen = (history.location.pathname.indexOf("/veranstaltungen") > -1 );
    const atReferenten = (history.location.pathname.indexOf("/referenten") > -1 );
    const atHersteller = (history.location.pathname === "/hersteller");
    const atVersorgungspartner = (this.props.history.location.pathname.indexOf("/versorgungspartner") > -1 );
    
    const match = history.location.pathname.match(/\/veranstaltungen\/(\d+)/) ||  history.location.pathname.match(/\/referenten\/(\d+)/);

    let filteredBackgroundImagesData = atVeranstaltungen
    ? backgroundImagesData.filter(({type}) => type === "Veranstaltung") : atReferenten ? backgroundImagesData.filter(({type}) => type === "Referent") : atVersorgungspartner ? backgroundImagesData.filter(({type}) => type === "Versorgungspartner") : atHersteller ? backgroundImagesData.filter(({type}) => type === "Hersteller") : backgroundImagesData;

    return (
      <div className="slider-div">
        { atHome && <Slider {...settings}>
          {filteredBackgroundImagesData &&
            filteredBackgroundImagesData.map(({ id, url }) => (
              <BackgroundImageDiv key={id} url={url} />
            ))}
        </Slider> }
        <div className={"veranstaltung-karte" + (match ? " veranstaltung-karte--leaf" : "")} style={{ display: ((atVeranstaltungen || atReferenten || atVersorgungspartner) ? 'inherit' : 'none'), height: match ? 248 : 474}}>
                <div style={{ marginTop: match ? -100 : -2}} id="leafletMap"></div>
          </div>
      </div>
    );
  }
}

class BackgroundImageDiv extends Component {
  render() {
    const url = `url(${this.props.url})`;
    return (
      <div
        className="background-image-div"
        style={{
          backgroundImage: url
        }}
      />
    );
  }
}

export default withRouter(BackgroundCaraousel);