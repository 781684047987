import React, { useEffect, useState, useContext, useRef } from "react";
import { Form, Input, Button, Select, Checkbox, DatePicker, Steps, Divider, Typography, message, Upload, Card,Row, Col } from 'antd';
import { PlusOutlined, UploadOutlined, UserOutlined, FormOutlined, SolutionOutlined, TeamOutlined, AppstoreOutlined, SettingOutlined, UsergroupAddOutlined, CheckCircleOutlined, FileTextOutlined, ContactsOutlined, AuditOutlined, PaperClipOutlined, InfoCircleOutlined, DollarOutlined, SaveOutlined, TrophyOutlined } from '@ant-design/icons';

export const Aktenaudit = [
                              {
                                "title": "1. Organisatorische Umsetzung des Lehrgangskonzeptes",
                                "icon": <FormOutlined />,
                                "forms": [
  {
    "label": "1.1 Die Präsentationen liegen von den Dozenten vor, die für den Unterricht gemeldet sind.",
    "type": "select",
    "key": "1.1",
    "options": ["voll", "teilweise", "nicht"]
  },
  {
    "label": "Anmerkungen:",
    "type": "string",
    "key": "Anmerkungen 1.1"
  },
  {
    "label": "1.2 Es wird eine Dozentenliste geführt und die Nachweise der Qualifikation des Dozenten liegen vor.",
    "type": "select",
    "key": "1.2",
    "options": ["voll", "teilweise", "nicht"]
  },
  {
    "label": "Anmerkungen:",
    "type": "string",
    "key": "Anmerkungen 1.2"
  },
  {
    "label": "1.3 Es findet eine Evaluation der Unterrichtssequenzen durch die Teilnehmer statt?",
    "type": "select",
    "key": "1.3",
    "options": ["voll", "teilweise", "nicht"]
  },
  {
    "label": "Anmerkungen:",
    "type": "string",
    "key": "Anmerkungen 1.3"
  },
  {
    "label": "1.4 Die Evaluation wird bewertet und ggf. Verbesserungen vorgenommen.",
    "type": "select",
    "key": "1.4",
    "options": ["voll", "teilweise", "nicht"]
  },
  {
    "label": "Anmerkungen:",
    "type": "string",
    "key": "Anmerkungen 1.4"
  }
                                ]
                              },
                              {
                                "title": "2. Qualitative Umsetzung des Lehrgangskonzeptes",
                                "icon": <FormOutlined />,
                                "forms": [
                                   {
                                      "label": "2.1 Die Unterrichtsunterlagen weisen das Unterrichtsthema (lt. Curriculum), den Dozenten, Autor, Inhalte sowie Quellenangaben aus.",
                                      "type": "select",
                                      "key": "2.1",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.1"
                                    },
                                    {
                                      "label": "2.2 Der lt. Skripten vermittelte Stoff entspricht den Lernzielen/Lerninhalten des aktuellen Curriculums.",
                                      "type": "select",
                                      "key": "2.2",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.2"
                                    },
                                    {
                                      "label": "2.3 Die Ausführungen entsprechen den aktuellen fachlichen Erkenntnissen (Literaturangaben im Curriculum).",
                                      "type": "select",
                                      "key": "2.3",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.3"
                                    },
                                    {
                                      "label": "2.4 Der Unterricht und die Ausarbeitungen sind produktneutral. Weisen die Präsentationen keine Firmenlogos auf? Liegen von verschiedenen Herstellern Medizinprodukte vor? (Verbandsstoffe, Kompressionsmaterial usw.)",
                                      "type": "select",
                                      "key": "2.4",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.4"
                                    },
                                    {
                                      "label": "2.5 Die Teilnehmer erhalten adäquate schriftliche oder EDV-gestützte Ausarbeitungen lt. Antrag, die zur Erreichung der Lernziele förderlich sind. (Umfang, Lesbarkeit, Aktualität…)",
                                      "type": "select",
                                      "key": "2.5",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.5"
                                    }
                                ]
                              },
                                        {
                                           printHide: true,
                                            title: '3. Bestätigung und Einreichen',
                                            icon: <TrophyOutlined />,
                                            htmlbox: `Um den Antrag fertigzustellen, drucken Sie bitte dieses Formular aus und laden es unterschrieben wieder hoch. Danach drücken Sie bitte auf "Einreichen" um den Antrag abzuschicken.`,
                                              forms: [
                                              { label: 'herunterladen', type: 'button' },
                                            { label: 'Unterschriebener Antrag:', type: 'upload' },
                                            { required: true, key: 'bestaetigung' , label: 'Die Auditergebnisse wurden mit dem Verantwortlichen des Bildungsanbieters besprochen und zur Kenntnis genommen. Notwendige Schlussfolgerungen und Korrekturmaßnahmen werden durch die Anerkennungs- und Zertifizierungsstelle von TÜV und ICW e. V. festgelegt und dem Anbieter in Schriftform zur Kenntnis gebracht.', type: 'checkbox' }],
                                          },// 2 unterschriften // Ort Datum
                            ];
