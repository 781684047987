import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import { actions, Table, Pagination }  from 'react-data-components';
import 'react-data-components/css/table-twbs.css';
import { API_BASE } from '../constant';
import HeadoutPicks from './Picks';
import Autocomplete from './Autocomplete';
import DataTable from './Table/CustomDataTable';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import { Col, Divider, List, Avatar } from 'antd';
import MedienCarousel from './MedienCarousel';

import './Styles/new-york.css';

const columns = [
  { title: '', prop: 'image', render: (image) => <div className="city-card-img city-card-img--table" style={{ backgroundImage: `url(${image})` }}></div> },
  { title: 'Veranstaltung', prop: 'label', render : (label) => <span className="label">{label}</span> },
  { title: 'Datum', prop: 'date', sortable: true, render: (date) => {
  var date = new Date(date);

  // Extracting day, month, and year components
  var day = date.getDate();
  var month = date.getMonth() + 1; // Adding 1 as months are 0-based
  var year = date.getFullYear() - 2000; // Assuming you want to represen

  var formattedDate = (day > 9 ? day : ('0' + day)) + '.' +  (month > 9 ? month : ('0' + month))  + '.' + year;
  return <span>{formattedDate}</span> }},
  { title: 'R-Punkte', prop: 'points', render : (points) => <span className="points">{points}</span> },
];

const columnsExtended = [ ...columns,
  { title: 'ICW Zertifizierung', prop: 'ICW', render: (ICW) => <span>{ICW ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times"></i>}</span> },
  { title: 'DGfw Zertifizierung', prop: 'DGfw', render: (ICW) => <span>{ICW ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times"></i>}</span>  },
];


class SelectableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  };

  deSelectRow = (row) => {
    this.setState({ selected: '' });
  };

  buildRowOptions = (row) => {
    return {
//      onMouseOver: () => this.selectRow(row),
//      onMouseOut: () => this.deSelectRow(row),
      className: this.state.selected === row.id ? 'active-row' : null,
    };
  };

  render() {
    return (
      <DataTable buildRowOptions={this.buildRowOptions} {...this.props} />
    );
  }
}

class Veranstaltung extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      selected: ''
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  }

//  buildRowOptions = (row) => { alert(row.name);
//   return {
//     onClick: this.selectRow.bind(this, row),
//     className: this.state.selected === row.id ? 'active' : null
//   }}

 formatDate = (date) => {
      var date = new Date(date);

      // Extracting day, month, and year components
      var day = date.getDate();
      var month = date.getMonth() + 1; // Adding 1 as months are 0-based
      var year = date.getFullYear() - 2000; // Assuming you want to represen

      var formattedDate = (day > 9 ? day : ('0' + day)) + '.' +  (month > 9 ? month : ('0' + month))  + '.' + year;
      return <span>{formattedDate}</span>
  }

  render() {

  const { veranstaltungenData, match } = this.props;
  const vId = match.params.id;

  const foundEntity = veranstaltungenData.find( v => v.id === +vId && v.type === "Hersteller");

const referenten = ((foundEntity || {}).veranstaltungs || {}).data || [] ;

  const referentendata = (referenten || []).filter(r => r.attributes ).map((d, i) =>  ({
            id : d.id,
            title: `${d.attributes.Veranstaltungsname}`,
            avatar: d.attributes.Bild ? API_BASE + d.attributes.Bild.data.attributes.url : "",
            description: <>
            <div className="edDrop edLimited" dangerouslySetInnerHTML={{ __html: d.attributes.Beschreibung }} /></>,
            content: null,
          }));

    return foundEntity ? (
      <>
  <CitySection
  style={{ marginTop: 32 }}
      key={1}
      headline={""}
      description={<>
      <div className={"leafContainer leafContainer--hersteller"}>
      { (((foundEntity.Logo || {}).data || {}).attributes) && <img className={"leafImage leafImage--produkt"} src={API_BASE + foundEntity.Logo.data.attributes.url}  />}
      <br/>
        <div className="row">
            <div className="col-md-6 veranstaltungen">
            <h2>{foundEntity.Herstellername}</h2>
            <div className="edDrop " dangerouslySetInnerHTML={{ __html: foundEntity.Beschreibung }} />
{ foundEntity.Homepage && <p>
        <hr />
<br />
            <span className="leaf--v">Weitere Informationen:</span>
            <a target="_blank" href={"https://" + foundEntity.Homepage} className="row external__link">https://{foundEntity.Homepage}</a>
        </p>}
            </div>
        </div>
        </div></>
        }
    />
    <br />
    <br />
    <br />

<div  className="city-section-wrapper">
    <HeadoutPicks all={false} noFocus={false} pickedData={veranstaltungenData.filter( v =>  v.type === "Produkt" && v.Hersteller === foundEntity.Herstellername )} headline={'Produkte von ' + foundEntity.Herstellername} />
    <br />
    { referenten.length > 0 && <Col xs={18} sm={18}>
                                  <Divider orientation="left">PMP Veranstaltungsteilnahmen</Divider>
                                      <List
                                          itemLayout="vertical"
                                          size="large"
                                          dataSource={referentendata}
                                          footer={
                                             (referenten || []).filter(r => !r.attributes ).length > 0 ? <div>
                                              <h4>Andere Referenten auf der Veranstaltung</h4>
                                              <p>{referenten.filter(r => !r.attributes ).map(r => r.Name).join(", ")}</p>
                                            </div> : null
                                          }
                                          renderItem={(item) => (
                                            <Link to={"/veranstaltungen/" + item.id}><List.Item
                                              key={item.title}
                                              actions={[
                                                <Link to={"/veranstaltungen/" + item.id}>Veranstaltung auf PMP Online ansehen</Link>
                                              ]}
                                            >
                                              <List.Item.Meta
                                                avatar={<Avatar shape="square" size={128} src={item.avatar} />}
                                                title={<a href={item.href}>{item.title}</a>}
                                                description={item.description}
                                              />
                                              {item.content}
                                            </List.Item></Link>
                                          )}
                                        /> </Col>

                                   }
                                   </div>
      </>
    ) : <h2>Hersteller nicht gefunden</h2>;
  }
}
//        <Media />
//        <Collections collectionsData={collectionsData} />
const CitySection = ({ style, headline, description, cardsData }) => (
  <div style={style} className="city-section-wrapper">
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      {headline}
    </h2>
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
      {description}
    </p>
    <div className="travel-card-wrapper">

    </div>
  </div>
);


// New York Navigation Data


// All New York City Sections Data ~ nested ( Dynamically Rendering )

// Collection Data


export default withRouter(Veranstaltung);