import React, { Component } from 'react';
import Slider from 'react-slick';
import ExperienceCard from './ExperienceCard';
import { Link } from 'react-router-dom';
import { Left } from './Arrows.js';
import { Right } from './Arrows';
import PdfViewer from '../components/PdfViewer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Styles/headout-picks.css';
import { Player } from 'video-react';
import { API_BASE } from '../constant';
import { Image } from 'antd';

class TopPicks extends Component {
  render() {
    const { overwriteTitle, pickedData, all, noFocus } = this.props;
    return (
      <div>
        <div className="headout-picks-wrapper">
          <div className="headout-picks-nav">
            <div>
              <h1>{ overwriteTitle ? overwriteTitle : "Produktbilder"}</h1>
            </div>
          </div>
          <hr
            style={{
              backgroundColor: '#eb008c',
              width: '75px',
              height: '2px',
              border: 'none',
              marginTop: '0px',
              marginLeft: '0px',
              marginBottom: '20px'
            }}
          />
          <div className="top-picked-carousel-wrapp">
            <PickedSlider pickedData={pickedData} noFocus={noFocus} />
          </div>
        </div>
      </div>
    );
  }
}

class PickedSlider extends React.Component {
  render() {

console.debug("pickedData", this.props.pickedData);


    const screenWidth = document.getElementById("root").clientWidth;

    var settings = {
      infinite: true,
      adaptiveHeight: true,
      speed: 500,
      slidesToShow: 1,
      rows: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <Right />,
      prevArrow: <Left />,

    };
    const { pickedData, noFocus } = this.props;
    return (
      <Slider {...settings}>
        {pickedData &&
          pickedData.map(
            ({
              url,
              id,
              name,
              city,
              image,
              Produktbild,
              Produktbeschreibung,
              Produktname,
              type,
              description,
              currency,
              currentPrice,
              ratings,
              Hersteller,
              Bewertung,
              discount,
              focus,
              Preis,
              about
            }) => {
                return (

<div className="city-card-wrapper">
        <div className="city-card" style={{ width: 360}}>
          <div
            className="city-card-img"
            style={{
            }}
          > {name && <h3 style={{ paddingTop: '1em' }}>{name}</h3>}
          { (url || "").toLowerCase().indexOf(".pdf") > -1 ?
          <PdfViewer className={"leafImage leafImage--veranstaltung"} name={name} pdf={API_BASE + url}  /> : (url || "").toLowerCase().indexOf(".flv") > -1 || (url || "").toLowerCase().indexOf(".webm") > -1 || (url || "").toLowerCase().indexOf(".wmv") > -1 || (url || "").toLowerCase().indexOf(".avi") > -1 || (url || "").toLowerCase().indexOf(".mov") > -1 || (url || "").toLowerCase().indexOf(".mp4") > -1 || (url || "").toLowerCase().indexOf(".mp3") > -1 ? <Player>
               <source src={API_BASE + url} />
             </Player>
           : <Image
                                preview={true}
                                src={(API_BASE + url)}
                              />}
          </div>
        </div>
      </div>)
            }
          )}
      </Slider>
    );
  }
}

export default TopPicks;
