import React from "react";
import { Button, Card, Col, Form, Popconfirm, Input, message, Row, Select, Spin } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import { useState } from "react";
import { getToken, removeExpire, removeToken } from "../../helpers";
import styled, { createGlobalStyle } from 'styled-components';

const confirm = (e, m) => {
  console.log(e);
  message.success(m || 'Plan storniert');
};
const cancel = (e, m) => {
  console.log(e);
  message.error(m || 'Plan nicht storniert');
};

// Global style for antd
const GlobalStyle = createGlobalStyle`
  @import '~antd/dist/antd.css';
`;

const { Option } = Select;

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const { user, sub, isLoading, setUser, availableSubs } = useAuthContext();

const deleteUser = async () => {


        const hasSub = sub ? (sub.status === "active" && !sub.cancel_at_period_end ? true : false) : false;

        if (hasSub) {
            cancel("", "Sie haben ein aktives Abonnement. Bitte kündigen Sie es zuerst.");
        } else {
            fetch(`${API}/users/${user.id}`, {
                    method: "DELETE",
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${getToken()}`,
                    },
                  })
                    .then(response => response.json())
                    .then(data => { console.log(data);
                        if (data.error) {
                            cancel("", "Es ging etwas schief");
                        } else {
                            confirm("", "Nutzer gelöscht. Sie werden in Kürze abgemeldet.");
                            logOut();
                        }
                    })
                    .catch((error) => { console.error('Error:', error);
                     cancel("", "Es ging etwas schief");
                     });
        }
}
  const logOut = () => {
    removeToken();
    removeExpire();
    setUser(null);
    window.location.replace("/anmelden");
  };

  const handleProfileUpdate = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/users/${user.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      setUser(responseData);
      message.success("Daten erfolgreich gespeichert!");
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  const isAuditor = user && user.Rolle === "Auditor";

  return (
  <>
    <Card className="profile_page_card">
      <Form
        layout="vertical"
        initialValues={{
          Name: (user || {}).Name,
          email: (user || {}).email,
          Titel: (user || {}).Titel,
          Anrede: (user || {}).Anrede,
          Addresse1: (user || {}).Addresse1,
          Addresse2: (user || {}).Addresse2,
          Addresse3: (user || {}).Addresse3,
          Addresse4: (user || {}).Addresse4,
          twitter_nutzername: (user || {}).twitter_nutzername,
          linkedin_nutzername: (user || {}).linkedin_nutzername,
          github_nutzername: (user || {}).github_nutzername,
          avatar_url: (user || {}).avatar_url,
          website_url: (user || {}).website_url,
          bio: (user || {}).bio,
          Rolle: (user || {}).Rolle,
        }}
        onFinish={handleProfileUpdate}
      >
        <Row gutter={[16, 16]}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Name"
              name="Name"
              rules={[
                {
                  required: true,
                  message: "Name ist ein Pflichtfeld!",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Vor- und Nachname" />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email ist ein Pflichtfeld!",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>

        </Row>
          { ( isAuditor) && (<Row gutter={[16, 16]}> <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Titel"
              name="Titel"
            >
                  <Select placeholder="Bitte auswählen">
                    {['', 'Dr.', 'Prof.'].map((option, idx) => (
                      <Option key={idx} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
            </Form.Item>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Anrede"
                name="Anrede"
                rules={[
                  {
                    required: true,
                    message: "Anrede ist ein Pflichtfeld!",
                  },
                ]}
              >
                    <Select placeholder="Bitte auswählen">
                      {['', 'Frau', 'Herr', 'Divers'].map((option, idx) => (
                        <Option key={idx} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
              </Form.Item>
            </Col>

          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Straße und Hausnummer"
              name="Addresse1"
              rules={[
                {
                  required: true,
                  message: "Addresse1 ist ein Pflichtfeld!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="PLZ und Ort"
              name="Addresse2"
              rules={[
                {
                  required: true,
                  message: "PLZ und Ort ist ein Pflichtfeld!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Adresszeile 3"
              name="Addresse3"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Adresszeile 4"
              name="Addresse4"
            >
              <Input />
            </Form.Item>
          </Col> </Row>)}
          {/*<Col span={6}>
               <Form.Item
                name="Rolle"
                label="Registrieren als"
                hasFeedback
                rules={[{ required: true, message: 'Registrieren als...' }]}
              >
                <Select placeholder="Registrieren als...">
                  <Option value="Anbieter">Anbieter</Option>
                  <Option value="Auditor">Auditor</Option>
                  <Option value="ICW-Admin">ICW-Admin</Option>
                </Select>
              </Form.Item>
          </Col> */}

         <Row gutter={[16, 16]}>
                  <Col md={8} lg={8} sm={24} xs={24}>
            <Button
              className="profile_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {loading ? (
                <>
                  <Spin size="small" /> Speichert...
                </>
              ) : (
                "Speichern"
              )}
            </Button>
            </Col>
        </Row>
         <Row gutter={[16, 16]}>
                  <Col md={8} lg={8} sm={24} xs={24}>
                  <Button style={{marginTop: 20 }}
                           onClick={() => logOut()}
                           className="profile_save_btn"
                           type="secondary"
                           size="large"
                         >
                             Abmelden

                         </Button>
                        { /*<Popconfirm
                             title="Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden."
                             onConfirm={deleteUser}
                             okText="Ja"
                             cancelText="Nein"
                           >
                                  <Button style={{marginTop: 20, marginLeft: 20 }}
                                     onClick={() => {}}
                                     className="profile_save_btn"
                                     type="danger"
                                     size="large"
                                   >
                                       Benutzerkonto löschen

                                   </Button>
                           </Popconfirm> */}
                </Col>
            </Row>

      </Form>
    </Card>
    </>
  );
};

export default Profile;
