import React, { Component } from 'react';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';

class Impressum extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    return (
      <>
<div className="city-section-wrapper static-padding"><br />
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      Impressum
    </h2>
    <hr
      style={{
        backgroundColor: '#eb008c',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <div
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
      <div>
        <h4>Angaben gemäß § 5 TMG</h4>
        <p>
          Initiative Chronische Wunden e.V.
          <br />
          Wipertistr. 1 a<br />
          06484 Quedlinburg
        </p>
        <p>
          Vereinsregister: VR 3548
          <br />
          Registergericht: Amtsgericht Stendal
        </p>
        <p>
          Vertreten durch:
          <br />
        </p>
        <ul>
          <li>Den Vorstand</li>
        </ul>
        <h4>Kontakt</h4>
        <p>
          Telefon: +49 (0)3946 979942
          <br />
          Telefax: +49 (0)3946 973496
          <br />
          E-Mail: organisation(at)
          <a href="http://icwunden.de" target="_blank">icwunden.de</a>
        </p>
        <h4>Redaktionell verantwortlich</h4>
        <p>
          Mike Zielonka
          <br />
          <br />
          E-Mail: geschaeftsstelle(at)
          <a href="http://icwunden.de" target="_blank">icwunden.<wbr />de</a>
        </p>
        <h4>Verbraucher­streit­beilegung/ <wbr />Universal­schlichtungs­stelle</h4>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
          einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </div>



    </div>
    <div className="travel-card-wrapper">

    </div>
  </div>

      </>
    );
  }
}

export default Impressum;
