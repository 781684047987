import React, { Component } from 'react';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';

class Erfolg extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    return (
      <>
<div className="city-section-wrapper static-padding">
<br />
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      Über ICW Portal: Anerkennungs- und Zertifizierungsstelle
    </h2>
    <hr
      style={{
        backgroundColor: '#eb008c',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
    <p>
      Informationen sind wertvoll, noch wertvoller wenn Sie schnell und unkompliziert verfügbar sind!
      </p><p>
      Aus diesem Grund haben wir diese Plattform entwickelt um eine schnelle Übersicht über Kongresse und Veranstaltungen im pflegerischen und medizinischen Bereich zu bekommen.
      </p><p>
      Außerdem bieten wir eine grosse Datenbank an Referenten mit Themengebieten und Honorarvorstellungen, sowie terminlicher Verfügbarkeit an.
      </p><p>
      Für Hersteller von Verband- oder Hilfsmitteln bieten wir eine zentrale Plattform um aktuelle Produktkataloge zur Verfügung zu stellen und auch Newcomer und Neuigkeiten zu verbreiten.
    </p></p>
    <br />
    <br />
    <div className="travel-card-wrapper">

    </div>
  </div>

      </>
    );
  }
}

export default Erfolg;
