import React, { Component } from 'react';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';

class Datenschutz extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    return (
      <>
<div className="city-section-wrapper static-padding"><br />
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      Datenschutzerklärung
    </h2>
    <hr
      style={{
        backgroundColor: '#eb008c',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
    <div className="c24 doc-content">
<div style={{ wordWrap: "break-word" }}>

  <h2
    data-fontsize={35}
    style={{ lineHeight: "1.2" }}
    data-lineheight="42px"
    className="fusion-responsive-typography-calculated"
  >
    1. Datenschutz auf einen Blick
  </h2>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Allgemeine Hinweise
  </h3>{" "}
  <p>
    Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
    Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
    Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
    identifiziert werden können. Ausführliche Informationen zum Thema
    Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
    Datenschutzerklärung.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Datenerfassung auf dieser Website
  </h3>{" "}
  <h4
    data-fontsize={28}
    style={{ lineHeight: "1.36" }}
    data-lineheight="38.08px"
    className="fusion-responsive-typography-calculated"
  >
    Wer ist verantwortlich für die Datenerfassung auf dieser Website?
  </h4>{" "}
  <p>
    Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
    Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen
    Stelle“ in dieser Datenschutzerklärung entnehmen.
  </p>{" "}
  <h4
    data-fontsize={28}
    style={{ lineHeight: "1.36" }}
    data-lineheight="38.08px"
    className="fusion-responsive-typography-calculated"
  >
    Wie erfassen wir Ihre Daten?
  </h4>{" "}
  <p>
    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
    Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein
    Kontaktformular eingeben.
  </p>{" "}
  <p>
    Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der
    Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten
    (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs).
    Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
    betreten.
  </p>{" "}
  <h4
    data-fontsize={28}
    style={{ lineHeight: "1.36" }}
    data-lineheight="38.08px"
    className="fusion-responsive-typography-calculated"
  >
    Wofür nutzen wir Ihre Daten?
  </h4>{" "}
  <p>
    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
    Website zu gewährleisten. Andere Daten können zur Analyse Ihres
    Nutzerverhaltens verwendet werden.
  </p>{" "}
  <h4
    data-fontsize={28}
    style={{ lineHeight: "1.36" }}
    data-lineheight="38.08px"
    className="fusion-responsive-typography-calculated"
  >
    Welche Rechte haben Sie bezüglich Ihrer Daten?
  </h4>{" "}
  <p>
    Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
    Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten.
    Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu
    verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
    können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
    haben Sie das Recht, unter bestimmten Umständen die Einschränkung der
    Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht
    Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
  </p>{" "}
  <p>
    Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
    jederzeit an uns wenden.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Analyse-Tools und Tools von Dritt­anbietern
  </h3>{" "}
  <p>
    Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet
    werden. Das geschieht vor allem mit sogenannten Analyseprogrammen.
  </p>{" "}
  <p>
    Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der
    folgenden Datenschutzerklärung.
  </p>
  <h2
    data-fontsize={35}
    style={{ lineHeight: "1.2" }}
    data-lineheight="42px"
    className="fusion-responsive-typography-calculated"
  >
    2. Allgemeine Hinweise und Pflicht­informationen
  </h2>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Datenschutz
  </h3>{" "}
  <p>
    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr
    ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
    entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
    Datenschutzerklärung.
  </p>{" "}
  <p>
    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten
    erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
    identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert,
    welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
    und zu welchem Zweck das geschieht.
  </p>{" "}
  <p>
    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.&nbsp;B. bei
    der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
    lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Hinweis zur verantwortlichen Stelle
  </h3>{" "}
  <p>
    Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
  </p>{" "}
  <p>
    Initiative Chronische Wunden e.V.
    <br />
    <br />
    Bundesgeschäftsstelle
    <br />
    <br />
    Wipertistr. 1 a<br />
    <br />
    D-06484 Quedlinburg
  </p>
  <p>
    Telefon: +49 (0)3946 979942
    <br />
    E-Mail: organisation(at)icwunden.de
  </p>
  <p>
    Verantwortliche Stelle ist die natürliche oder juristische Person, die
    allein oder gemeinsam mit anderen über die Zwecke und Mittel der
    Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen
    o. Ä.) entscheidet.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Speicherdauer
  </h3>{" "}
  <p>
    Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer
    genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der
    Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes
    Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung
    widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich
    zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben
    (z.&nbsp;B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
    letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser
    Website
  </h3>{" "}
  <p>
    Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre
    personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw.
    Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9
    Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung
    in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die
    Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO.
    Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf
    Informationen in Ihr Endgerät (z.&nbsp;B. via Device-Fingerprinting)
    eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage
    von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre
    Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
    erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1
    lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur
    Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage
    von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
    Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO
    erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird
    in den folgenden Absätzen dieser Datenschutzerklärung informiert.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Datenschutz­beauftragter
  </h3>{" "}
  <p>Wir haben einen Datenschutzbeauftragten benannt.</p>
  <p>
    Mike Zielonka
    <br />
    Wipertistr. 1 a<br />
    D-06484 Quedlinburg
  </p>
  <p>
    Telefon: +49 (0)3946 979942
    <br />
    E-Mail: geschaeftsstelle(at)icwunden.de
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Empfänger von personenbezogenen Daten
  </h3>{" "}
  <p>
    Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen
    Stellen zusammen. Dabei ist teilweise auch eine Übermittlung von
    personenbezogenen Daten an diese externen Stellen erforderlich. Wir geben
    personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im
    Rahmen einer Vertragserfüllung erforderlich ist, wenn wir gesetzlich hierzu
    verpflichtet sind (z.&nbsp;B. Weitergabe von Daten an Steuerbehörden), wenn
    wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der
    Weitergabe haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe
    erlaubt. Beim Einsatz von Auftragsverarbeitern geben wir personenbezogene
    Daten unserer Kunden nur auf Grundlage eines gültigen Vertrags über
    Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird
    ein Vertrag über gemeinsame Verarbeitung geschlossen.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Widerruf Ihrer Einwilligung zur Datenverarbeitung
  </h3>{" "}
  <p>
    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
    Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
    jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
    Datenverarbeitung bleibt vom Widerruf unberührt.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
    Direktwerbung (Art. 21 DSGVO)
  </h3>{" "}
  <p>
    WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
    DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
    IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
    PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF
    DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF
    DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG.
    WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN
    DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE
    SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN,
    RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
    GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH
    NACH ART. 21 ABS. 1 DSGVO).
  </p>{" "}
  <p>
    WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
    BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
    VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
    WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER
    DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
    PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
    DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Beschwerde­recht bei der zuständigen Aufsichts­behörde
  </h3>{" "}
  <p>
    Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
    Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
    Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des
    Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet
    anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Recht auf Daten­übertrag­barkeit
  </h3>{" "}
  <p>
    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
    Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen
    Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
    Sofern Sie die direkte Übertragung der Daten an einen anderen
    Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
    ist.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Auskunft, Berichtigung und Löschung
  </h3>{" "}
  <p>
    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
    Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen
    Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und
    ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
    weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit
    an uns wenden.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Recht auf Einschränkung der Verarbeitung
  </h3>{" "}
  <p>
    Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
    personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an
    uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
    folgenden Fällen:
  </p>{" "}
  <ul>
    {" "}
    <li>
      Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
      Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen.
      Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der
      Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
    </li>{" "}
    <li>
      Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
      geschah/geschieht, können Sie statt der Löschung die Einschränkung der
      Datenverarbeitung verlangen.
    </li>{" "}
    <li>
      Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch
      zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen
      benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der
      Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
    </li>{" "}
    <li>
      Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss
      eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden.
      Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das
      Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
      verlangen.
    </li>{" "}
  </ul>{" "}
  <p>
    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben,
    dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer
    Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
    Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
    juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses
    der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    SSL- bzw. TLS-Verschlüsselung
  </h3>{" "}
  <p>
    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
    vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie
    an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine
    verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
    Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
    Ihrer Browserzeile.
  </p>{" "}
  <p>
    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die
    Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Verschlüsselter Zahlungsverkehr auf dieser Website
  </h3>{" "}
  <p>
    Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
    Verpflichtung, uns Ihre Zahlungsdaten (z.&nbsp;B. Kontonummer bei
    Einzugsermächtigung) zu übermitteln, werden diese Daten zur
    Zahlungsabwicklung benötigt.
  </p>{" "}
  <p>
    Der Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard,
    Lastschriftverfahren) erfolgt ausschließlich über eine verschlüsselte SSL-
    bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie daran, dass
    die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an
    dem Schloss-Symbol in Ihrer Browserzeile.
  </p>{" "}
  <p>
    Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an uns
    übermitteln, nicht von Dritten mitgelesen werden.
  </p>
  <h2
    data-fontsize={35}
    style={{ lineHeight: "1.2" }}
    data-lineheight="42px"
    className="fusion-responsive-typography-calculated"
  >
    3. Datenerfassung auf dieser Website
  </h2>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Cookies
  </h3>{" "}
  <p>
    Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine
    Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden
    entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder
    dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert.
    Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht.
    Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
    selbst löschen oder eine automatische Löschung durch Ihren Webbrowser
    erfolgt.
  </p>{" "}
  <p>
    Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen
    stammen (sog. Third-Party-Cookies). Third-Party-Cookies ermöglichen die
    Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb von
    Webseiten (z.&nbsp;B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
  </p>{" "}
  <p>
    Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
    notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren
    würden (z.&nbsp;B. die Warenkorbfunktion oder die Anzeige von Videos).
    Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu
    Werbezwecken verwendet werden.
  </p>{" "}
  <p>
    Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur
    Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.&nbsp;B. für
    die Warenkorbfunktion) oder zur Optimierung der Website (z.&nbsp;B. Cookies
    zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden
    auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine
    andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
    berechtigtes Interesse an der Speicherung von notwendigen Cookies zur
    technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern
    eine Einwilligung zur Speicherung von Cookies und vergleichbaren
    Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung
    ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO
    und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.
  </p>{" "}
  <p>
    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
    informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von
    Cookies für bestimmte Fälle oder generell ausschließen sowie das
    automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei
    der Deaktivierung von Cookies kann die Funktionalität dieser Website
    eingeschränkt sein.
  </p>{" "}
  <p>
    Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie
    dieser Datenschutzerklärung entnehmen.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Einwilligung mit Borlabs Cookie
  </h3>{" "}
  <p>
    Unsere Website nutzt die Consent-Technologie von Borlabs Cookie, um Ihre
    Einwilligung zur Speicherung bestimmter Cookies in Ihrem Browser oder zum
    Einsatz bestimmter Technologien einzuholen und diese datenschutzkonform zu
    dokumentieren. Anbieter dieser Technologie ist die Borlabs GmbH, Rübenkamp
    32, 22305 Hamburg (im Folgenden Borlabs).
  </p>{" "}
  <p>
    Wenn Sie unsere Website betreten, wird ein Borlabs-Cookie in Ihrem Browser
    gespeichert, in dem die von Ihnen erteilten Einwilligungen oder der Widerruf
    dieser Einwilligungen gespeichert werden. Diese Daten werden nicht an den
    Anbieter von Borlabs Cookie weitergegeben.
  </p>{" "}
  <p>
    Die erfassten Daten werden gespeichert, bis Sie uns zur Löschung auffordern
    bzw. das Borlabs-Cookie selbst löschen oder der Zweck für die
    Datenspeicherung entfällt. Zwingende gesetzliche Aufbewahrungsfristen
    bleiben unberührt. Details zur Datenverarbeitung von Borlabs Cookie finden
    Sie unter{" "}
    <a
      href="https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/"
      target="_blank"
      rel="noopener noreferrer"
    >
      https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/
    </a>
    .
  </p>{" "}
  <p>
    Der Einsatz der Borlabs-Cookie-Consent-Technologie erfolgt, um die
    gesetzlich vorgeschriebenen Einwilligungen für den Einsatz von Cookies
    einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Server-Log-Dateien
  </h3>{" "}
  <p>
    Der Provider der Seiten erhebt und speichert automatisch Informationen in so
    genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
    übermittelt. Dies sind:
  </p>{" "}
  <ul>
    {" "}
    <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li>{" "}
    <li>Referrer URL</li> <li>Hostname des zugreifenden Rechners</li>{" "}
    <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
  </ul>{" "}
  <p>
    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
    vorgenommen.
  </p>{" "}
  <p>
    Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
    DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch
    fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen
    die Server-Log-Files erfasst werden.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Kontaktformular
  </h3>{" "}
  <p>
    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
    Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
    Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
    Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
    Einwilligung weiter.
  </p>{" "}
  <p>
    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b
    DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt
    oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen
    übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an
    der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
    lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
    sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
  </p>{" "}
  <p>
    Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis
    Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
    widerrufen oder der Zweck für die Datenspeicherung entfällt (z.&nbsp;B. nach
    abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
    Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Anfrage per E-Mail, Telefon oder Telefax
  </h3>{" "}
  <p>
    Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
    Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name,
    Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und
    verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
  </p>{" "}
  <p>
    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b
    DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt
    oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen
    übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an
    der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
    lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
    sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
  </p>{" "}
  <p>
    Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei
    uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
    widerrufen oder der Zweck für die Datenspeicherung entfällt (z.&nbsp;B. nach
    abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche
    Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben
    unberührt.
  </p>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Registrierung auf dieser Website
  </h3>{" "}
  <p>
    Sie können sich auf dieser Website registrieren, um zusätzliche Funktionen
    auf der Seite zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum
    Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich
    registriert haben. Die bei der Registrierung abgefragten Pflichtangaben
    müssen vollständig angegeben werden. Anderenfalls werden wir die
    Registrierung ablehnen.
  </p>{" "}
  <p>
    Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
    notwendigen Änderungen nutzen wir die bei der Registrierung angegebene
    E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
  </p>{" "}
  <p>
    Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum
    Zwecke der Durchführung des durch die Registrierung begründeten
    Nutzungsverhältnisses und ggf. zur Anbahnung weiterer Verträge (Art. 6 Abs.
    1 lit. b DSGVO).
  </p>{" "}
  <p>
    Die bei der Registrierung erfassten Daten werden von uns gespeichert,
    solange Sie auf dieser Website registriert sind und werden anschließend
    gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
  </p>
  <h2
    data-fontsize={35}
    style={{ lineHeight: "1.2" }}
    data-lineheight="42px"
    className="fusion-responsive-typography-calculated"
  >
    4. Analyse-Tools und Werbung
  </h2>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Google Analytics
  </h3>{" "}
  <p>
    Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
    Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow
    Street, Dublin 4, Irland.
  </p>{" "}
  <p>
    Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der
    Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber
    verschiedene Nutzungsdaten, wie z.&nbsp;B. Seitenaufrufe, Verweildauer,
    verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden dem
    jeweiligen Endgerät des Users zugeordnet. Eine Zuordnung zu einer User-ID
    erfolgt nicht.
  </p>{" "}
  <p>
    Des Weiteren können wir mit Google Analytics u.&nbsp;a. Ihre Maus- und
    Scrollbewegungen und Klicks aufzeichnen. Ferner verwendet Google Analytics
    verschiedene Modellierungsansätze, um die erfassten Datensätze zu ergänzen
    und setzt Machine-Learning-Technologien bei der Datenanalyse ein.
  </p>
  <p>
    Google Analytics verwendet Technologien, die die Wiedererkennung des Nutzers
    zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z.&nbsp;B. Cookies
    oder Device-Fingerprinting). Die von Google erfassten Informationen über die
    Benutzung dieser Website werden in der Regel an einen Server von Google in
    den USA übertragen und dort gespeichert.
  </p>{" "}
  <p>
    Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach
    Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung ist
    jederzeit widerrufbar.
  </p>{" "}
  <p>
    Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
    EU-Kommission gestützt. Details finden Sie hier:{" "}
    <a
      href="https://privacy.google.com/businesses/controllerterms/mccs/"
      target="_blank"
      rel="noopener noreferrer"
    >
      https://privacy.google.com/businesses/controllerterms/mccs/
    </a>
    .
  </p>{" "}
  <p>
    Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
    Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
    Europäischen Union und den USA, der die Einhaltung europäischer
    Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll.
    Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
    Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie
    vom Anbieter unter folgendem Link:{" "}
    <a
      href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
      target="_blank"
      rel="noopener noreferrer"
    >
      https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&amp;id=a2zt000000001L5AAI&amp;status=Active
    </a>
  </p>
  <h4
    data-fontsize={28}
    style={{ lineHeight: "1.36" }}
    data-lineheight="38.08px"
    className="fusion-responsive-typography-calculated"
  >
    IP Anonymisierung
  </h4>{" "}
  <p>
    Die Google Analytics IP-Anonymisierung ist aktiviert. Dadurch wird Ihre
    IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union
    oder in anderen Vertragsstaaten des Abkommens über den Europäischen
    Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in
    Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den
    USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website
    wird Google diese Informationen benutzen, um Ihre Nutzung der Website
    auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um
    weitere mit der Websitenutzung und der Internetnutzung verbundene
    Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen
    von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht
    mit anderen Daten von Google zusammengeführt.
  </p>
  <h4
    data-fontsize={28}
    style={{ lineHeight: "1.36" }}
    data-lineheight="38.08px"
    className="fusion-responsive-typography-calculated"
  >
    Browser Plugin
  </h4>{" "}
  <p>
    Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
    verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
    herunterladen und installieren:{" "}
    <a
      href="https://tools.google.com/dlpage/gaoptout?hl=de"
      target="_blank"
      rel="noopener noreferrer"
    >
      https://tools.google.com/dlpage/gaoptout?hl=de
    </a>
    .
  </p>{" "}
  <p>
    Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden
    Sie in der Datenschutzerklärung von Google:{" "}
    <a
      href="https://support.google.com/analytics/answer/6004245?hl=de"
      target="_blank"
      rel="noopener noreferrer"
    >
      https://support.google.com/analytics/answer/6004245?hl=de
    </a>
    .
  </p>
  <h4
    data-fontsize={28}
    style={{ lineHeight: "1.36" }}
    data-lineheight="38.08px"
    className="fusion-responsive-typography-calculated"
  >
    Auftragsverarbeitung
  </h4>{" "}
  <p>
    Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen
    und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der
    Nutzung von Google Analytics vollständig um.
  </p>
  <h2
    data-fontsize={35}
    style={{ lineHeight: "1.2" }}
    data-lineheight="42px"
    className="fusion-responsive-typography-calculated"
  >
    5. Plugins und Tools
  </h2>
  <h3
    data-fontsize={32}
    style={{ lineHeight: "1.3" }}
    data-lineheight="41.6px"
    className="fusion-responsive-typography-calculated"
  >
    Google Fonts (lokales Hosting)
  </h3>{" "}
  <p>
    Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte
    Google Fonts, die von Google bereitgestellt werden. Die Google Fonts sind
    lokal installiert. Eine Verbindung zu Servern von Google findet dabei nicht
    statt.
  </p>{" "}
  <p>
    Weitere Informationen zu Google Fonts finden Sie unter{" "}
    <a
      href="https://developers.google.com/fonts/faq"
      target="_blank"
      rel="noopener noreferrer"
    >
      https://developers.google.com/fonts/faq
    </a>{" "}
    und in der Datenschutzerklärung von Google:{" "}
    <a
      href="https://policies.google.com/privacy?hl=de"
      target="_blank"
      rel="noopener noreferrer"
    >
      https://policies.google.com/privacy?hl=de
    </a>
    .
  </p>
</div>


    </div>
</p>
    </div>
  </>


    );
  }
}

export default Datenschutz;
