import React, { Component } from 'react';
import Routes from './Routes';
import logo from './Components/Images/ICW_Logo_mobile.png';

class App extends Component {
  state = { width: 0, height: 0 };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions = () =>
    this.setState({ width: window.innerWidth, height: window.innerHeight });

  render() {
    const { width } = this.state;
    const mobileTablet = width <= 1100;

    if (mobileTablet && false) {
      return (
        <div className="mobile-tablet">
          <img src={logo} alt="Headout" />
          <p>
            Noch keine Mobile & Tablets Version verfügbar
          </p>
        </div>
      );
    } else {
      return <Routes />;
    }
  }
}

export default App;
