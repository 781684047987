import React, { useEffect, useState, useContext, useRef } from "react";
import { Form, Input, Button, Select, Checkbox, DatePicker, Steps, Divider, Typography, message, Upload, Card,Row, Col } from 'antd';
import { PlusOutlined, UploadOutlined, UserOutlined, FormOutlined, SolutionOutlined, TeamOutlined, AppstoreOutlined, SettingOutlined, UsergroupAddOutlined, CheckCircleOutlined, FileTextOutlined, ContactsOutlined, AuditOutlined, PaperClipOutlined, InfoCircleOutlined, DollarOutlined, SaveOutlined, TrophyOutlined } from '@ant-design/icons';

export const Seminaraudit = [
                              {
                                "title": "1. Allgemeine Anforderungen an den Veranstalter (Prüffeld 1)",
                                "icon": <FormOutlined />,
                                "forms": [
                                  {
                                    "label": "Auditteilnehmer des Anbieters",
                                    "type": "string",
                                    "key": "Auditteilnehmer des Anbieters"
                                  },
                                  {
                                    "label": "Auditor/en",
                                    "type": "string",
                                    "key": "Auditor/en"
                                  },
                                  {
                                    "label": "Auditart",
                                    "type": "select",
                                    "key": "Auditart",
                                    "options": ["Regelaudit", "Überwachungsaudit", "Berücksichtigung von Auflagen vorangegangener Audits"]
                                  },
                                  {
                                    "label": "Audittyp",
                                    "type": "select",
                                    "key": "Audittyp",
                                    "options": ["Aktenaudit", "Prüfungsaudit", "Rezertifizierungsausdit", "Seminaraudit"]
                                  },
                                  {
                                    "label": "1.1 Pädagogische und fachliche Leitungen entsprechen dem Anerkennungsantrag.",
                                    "type": "select",
                                    "key": "1.1",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 1.1"
                                  },
                                  {
                                    "label": "1.2 Ein Teilnahmenachweis Leitungsseminar ICW/TÜV liegt für pädagogische und fachliche Leitung vor.",
                                    "type": "select",
                                    "key": "1.2",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 1.2"
                                  },
                                  {
                                    "label": "1.3 Die fachliche und pädagogische Leitung ist im Unterricht eingebunden. Sie sind den Teilnehmern bekannt und für diese zuverlässig erreichbar.",
                                    "type": "select",
                                    "key": "1.3",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 1.3"
                                  },
                                  {
                                    "label": "1.4 Die Ausschreibung der Bildungsmaßnahme entspricht den aktuellen Kriterien der ICW/TÜV.",
                                    "type": "select",
                                    "key": "1.4",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 1.4"
                                  },
                                  {
                                    "label": "1.5 Die Bildungsmaßnahme findet am beantragten Ort und zur gemeldeten Zeit statt.",
                                    "type": "select",
                                    "key": "1.5",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 1.5"
                                  },
                                  {
                                    "label": "1.6 Die Anwesenheit und die Fehlzeiten der Teilnehmer wird/werden mit Unterschriftenliste dokumentiert.",
                                    "type": "select",
                                    "key": "1.6",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 1.6"
                                  },
                                  {
                                    "label": "1.7 Die zeitliche Anwesenheit der Teilnehmer wird erfasst, so dass versäumte Stunden gekennzeichnet sind.",
                                    "type": "select",
                                    "key": "1.7",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 1.7"
                                  },
                                  {
                                    "label": "1.8 Die Lehrbeauftragten bestätigen den tatsächlich erbrachten Unterricht durch Unterschrift.",
                                    "type": "select",
                                    "key": "1.8",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 1.8"
                                  }
                                ]
                              },
                              {
                                "title": "2. Organisatorische Umsetzung des Lehrgangskonzeptes (Prüffeld 2)",
                                "icon": <FormOutlined />,
                                "forms": [
                                  {
                                    "label": "2.1 Der Unterrichtsplan entspricht der eingereichten Stundenplanung.",
                                    "type": "select",
                                    "key": "2.1",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 2.1"
                                  },
                                  {
                                    "label": "2.2 Die geplanten Unterrichtsstunden werden inhaltlich und zeitlich eingehalten.",
                                    "type": "select",
                                    "key": "2.2",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 2.2"
                                  },
                                  {
                                    "label": "2.3 Der Unterricht wird durch die bestätigten Dozenten erbracht.",
                                    "type": "select",
                                    "key": "2.3",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 2.3"
                                  },
                                  {
                                    "label": "2.4 Es wird eine Dozentenliste geführt. Die Nachweise der Qualifikation der Dozenten liegen vor und weisen die Aktualität der fachbezogenen Qualifizierung aus.",
                                    "type": "select",
                                    "key": "2.4",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 2.4"
                                  },
                                  {
                                    "label": "2.5 Den/dem Dozenten ist das Curriculum bekannt.",
                                    "type": "select",
                                    "key": "2.5",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 2.5"
                                  },
                                  {
                                    "label": "2.6 Es findet eine Evaluation durch die Teilnehmer statt, die intern ausgewertet wird.",
                                    "type": "select",
                                    "key": "2.6",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 2.6"
                                  },
                                  {
                                    "label": "2.7 Die Teilnehmer erhalten Informationen zu: Curriculum inkl. Prüfungsordnung, Normatives Dokument, Literaturliste, Positionspapier sowie Rezertifizierung und bestätigen die Kenntnis per Unterschrift.",
                                    "type": "select",
                                    "key": "2.7",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 2.7"
                                  },
                                  {
                                    "label": "2.8 Die Anwesenheit und die Fehlzeiten der Teilnehmer wird/werden mit Unterschriftenliste dokumentiert.",
                                    "type": "select",
                                    "key": "2.8",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 2.8"
                                  },
                                  {
                                    "label": "2.9 Die zeitliche Anwesenheit der Teilnehmer wird erfasst, so dass versäumte Stunden gekennzeichnet sind.",
                                    "type": "select",
                                    "key": "2.9",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 2.9"
                                  },
                                  {
                                    "label": "2.10 Die Lehrbeauftragten bestätigen den tatsächlich erbrachten Unterricht durch Unterschrift.",
                                    "type": "select",
                                    "key": "2.10",
                                    "options": ["voll", "teils", "nicht"]
                                  }
                                ]
                              },
                              {
                                "title": "3. Qualitative Umsetzung des Unterrichtes (Prüffeld 3)",
                                "icon": <FormOutlined />,
                                "forms": [
                                  {
                                    "label": "3.1 Der vermittelte Stoff entspricht den Lernzielen/Lerninhalten des Curriculums.",
                                    "type": "select",
                                    "key": "3.1",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.1"
                                  },
                                  {
                                    "label": "3.2 Die Ausführungen entsprechen den aktuellen fachlichen Erkenntnissen (siehe Literaturangaben im Curriculum).",
                                    "type": "select",
                                    "key": "3.2",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.2"
                                  },
                                  {
                                    "label": "3.3 Die methodisch didaktische Gestaltung des Unterrichts ist dem Unterrichtsinhalt und der Zielgruppe angemessen.",
                                    "type": "select",
                                    "key": "3.3",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.3"
                                  },
                                  {
                                    "label": "3.4 Es finden, wie curricular geplant, praktische Übungen statt. Anschauungsmaterial wird vorgestellt und eingesetzt.",
                                    "type": "select",
                                    "key": "3.4",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.4"
                                  },
                                  {
                                    "label": "3.5 Der Unterricht und die Ausarbeitungen sind firmen- und produktneutral. Präsentationen weisen keine Firmenlogos auf. Es liegen von verschiedenen Herstellern Produkte aus.",
                                    "type": "select",
                                    "key": "3.5",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.5"
                                  },
                                  {
                                    "label": "3.6 Den Teilnehmern wird Fachliteratur (mindestens lt. Curriculum) zur Verfügung gestellt.",
                                    "type": "select",
                                    "key": "3.6",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.6"
                                  },
                                  {
                                    "label": "3.7 Die Teilnehmer erhalten adäquate (Umfang, Lesbarkeit, Aktualität, Praxisrelevanz, ...) schriftliche oder EDV-gestützte Ausarbeitungen, die zur Erreichung der Lernziele förderlich sind.",
                                    "type": "select",
                                    "key": "3.7",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.7"
                                  },
                                  {
                                    "label": "3.8 Die Unterrichtsunterlagen weisen das curriculare Thema, den Dozenten, Autor, die Inhalte sowie Quellenangaben aus.",
                                    "type": "select",
                                    "key": "3.8",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.8"
                                  },
                                  {
                                    "label": "3.9 Die Unterrichtsunterlagen wurden von einer der Seminarleitungen gesichtet/bewertet.",
                                    "type": "select",
                                    "key": "3.9",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.9"
                                  }
                                ]
                              },
                              {
                                "title": "4. Einhaltung der Prüfungsordnung (Prüffeld 4)",
                                "icon": <FormOutlined />,
                                "forms": [
                                  {
                                    "label": "4.1 Die Prüfung findet unter angemessenen räumlichen und organisatorischen Voraussetzungen statt. Bei großen Gruppen in 2 Räumen oder es ist eine 2. Klausur angefordert worden.",
                                    "type": "select",
                                    "key": "4.1",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.1"
                                  },
                                  {
                                    "label": "4.2 Die Prüfungsaufsicht wird ihren Aufgaben gerecht (Einweisung, keine fachlichen Hilfen, Unterbindung unerlaubter Aktivitäten, Umsetzung der Prüfungsordnung).",
                                    "type": "select",
                                    "key": "4.2",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.2"
                                  },
                                  {
                                    "label": "4.3 Eine für die Teilnehmer nachvollziehbare und transparente Prüfungseinweisung findet statt.",
                                    "type": "select",
                                    "key": "4.3",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.3"
                                  },
                                  {
                                    "label": "4.4 Die Prüfungsfragen sind identisch mit dem Original Prüfbogen der ICW/TÜV.",
                                    "type": "select",
                                    "key": "4.4",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.4"
                                  },
                                  {
                                    "label": "4.5 Die Bewertung der Prüfung erfolgt durch die bestätigten Mitglieder des Prüfungsgremiums.",
                                    "type": "select",
                                    "key": "4.5",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.5"
                                  },
                                  {
                                    "label": "4.6 Die Erbringung der Zugangsvoraussetzungen der Teilnehmer wird geprüft und dokumentiert (geforderte berufliche Qualifizierung, 80% Anwesenheit an der Lehrveranstaltung).",
                                    "type": "select",
                                    "key": "4.6",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.6"
                                  },
                                  {
                                    "label": "4.7 Die Bewertung der Prüfungen erfolgt gemäß den Bewertungsvorgaben. Die Prüfungsfragen werden entsprechend der Lösungsvorgaben, die Hausarbeit entsprechend der Bewertungsmatrix der ICW/TÜV ausgewertet.",
                                    "type": "select",
                                    "key": "4.7",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.7"
                                  },
                                  {
                                    "label": "4.8 Die Bewertung der Prüfungen ist nachvollziehbar und wird durch Prüfungsbeauftragten gezeichnet.",
                                    "type": "select",
                                    "key": "4.8",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.8"
                                  },
                                  {
                                    "label": "4.9 Die Bewertung der Prüfungen ist fachlich korrekt.",
                                    "type": "select",
                                    "key": "4.9",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.9"
                                  },
                                  {
                                    "label": "4.10 Werden die Prüfungsunterlagen wie vorgegeben archiviert und systematisch abgelegt? Prüfungsunterlagen einzelner Teilnehmer sind dadurch auffindbar.",
                                    "type": "select",
                                    "key": "4.10",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.10"
                                  }
                                ]
                              },
                                        {
                                           printHide: true,
                                            title: '5. Bestätigung und Einreichen',
                                            icon: <TrophyOutlined />,
                                            htmlbox: `Um den Antrag fertigzustellen, drucken Sie bitte dieses Formular aus und laden es unterschrieben wieder hoch. Danach drücken Sie bitte auf "Einreichen" um den Antrag abzuschicken.`,
                                              forms: [
                                              { label: 'herunterladen', type: 'button' },
                                            { label: 'Unterschriebener Antrag:', type: 'upload' },
                                            { required: true, key: 'bestaetigung' , label: 'Die Auditergebnisse wurden mit dem Verantwortlichen des Bildungsanbieters besprochen und zur Kenntnis genommen. Notwendige Schlussfolgerungen und Korrekturmaßnahmen werden durch die Anerkennungs- und Zertifizierungsstelle von TÜV und ICW e. V. festgelegt und dem Anbieter in Schriftform zur Kenntnis gebracht.', type: 'checkbox' }],
                                          },// 2 unterschriften // Ort Datum
                            ];
