import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import List from "../components/Veste/List";
import { getToken } from "../helpers";
import './Styles/new-york.css';

class MeineInserate extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    const token = getToken();

    if (!token) {
        return this.props.history.push(`/anmelden`);
    }
    // /anmelden

    return (
      <>
<div className="city-section-wrapper static-padding"><br /><br /><br /><br />
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      Meine Inserate
    </h2>
    <hr
      style={{
        backgroundColor: '#eb008c',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    { <List veranstaltungenData={this.props.veranstaltungenData} /> }

    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
    </p>
    <div className="travel-card-wrapper">

    </div>
  </div>

      </>
    );
  }
}

export default withRouter(MeineInserate);
