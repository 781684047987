import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { Link, withRouter } from "react-router-dom";
import { actions, Table, Pagination }  from 'react-data-components';
import 'react-data-components/css/table-twbs.css';

import MedienCarousel from './MedienCarousel';
import Autocomplete from './Autocomplete';
import DataTable from './Table/CustomDataTable';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';

import { API_BASE } from '../constant';
import { Row, Col,Divider, Button, Typography, Tag } from 'antd';
import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import { Avatar, List, Space } from 'antd';

const { Title, Text } = Typography;

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const columns = [
  { title: '', prop: 'image', render: (image) => <div className="city-card-img city-card-img--table" style={{ backgroundImage: `url(${image})` }}></div> },
  { title: 'Veranstaltung', prop: 'label', render : (label) => <span className="label">{label}</span> },
  { title: 'Datum', prop: 'date', sortable: true, render: (date) => {
  var date = new Date(date);

  // Extracting day, month, and year components
  var day = date.getDate();
  var month = date.getMonth() + 1; // Adding 1 as months are 0-based
  var year = date.getFullYear() - 2000; // Assuming you want to represen

  var formattedDate = (day > 9 ? day : ('0' + day)) + '.' +  (month > 9 ? month : ('0' + month))  + '.' + year;
  return <span>{formattedDate}</span> }},
  { title: 'R-Punkte', prop: 'points', render : (points) => <span className="points">{points}</span> },
];


const EventDetails = ({ eventDetails }) => {
  return (
    <div style={{ padding: '20px', border: '1px solid #eb008c' }}>
      <Divider orientation="left">Alle Informationen auf einen Blick</Divider>
      <Row gutter={[24, 24]}>
          {eventDetails.Veranstaltername && <Col xs={24} sm={24}>
            <Text  ><strong>Veranstalter:</strong> {eventDetails.Veranstaltername}</Text>
          </Col>}



          {eventDetails.Startzeit &&  <Col xs={24} sm={24}>
            <Text ><strong>Start:</strong> {moment(eventDetails.Startzeit).format("DD.MM.YY HH:mm")}</Text>
          </Col>}



          {eventDetails.Endzeit && <Col xs={24} sm={24}>
            <Text><strong>Ende:</strong> {moment(eventDetails.Startzeit).format("DD.MM.YY HH:mm")}</Text>
          </Col>}



          {eventDetails.ErwarteteTeilnehmerzahl && <Col xs={24} sm={24}>
            <Text><strong>Erwartete Teilnehmerzahl:</strong> {eventDetails.ErwarteteTeilnehmerzahl.substr(1)}</Text>
          </Col>}



          {eventDetails.Veranstalter && <Col xs={24} sm={24}>
            <Text><strong>Veranstalter:</strong> {eventDetails.Veranstaltername}</Text>
          </Col>}



          {eventDetails.AnbieterNummer && <Col xs={24} sm={24}>
            <Text><strong>Anbieter Nummer:</strong> {eventDetails.AnbieterNummer}</Text>
          </Col>}



          {eventDetails.Ansprechpartner && <Col xs={24} sm={24}>
            <Text><strong>Ansprechpartner:</strong> {eventDetails.Ansprechpartner}</Text>
          </Col>}



          {eventDetails.Teilnehmergebuehr && <Col xs={24} sm={24}>
            <Text><strong>Teilnehmergebuehr:</strong> {eventDetails.Teilnehmergebuehr.substr(1)}</Text>
          </Col>}




          {eventDetails.PLZ && <Col xs={12} sm={12}>
            <Text><strong>PLZ:</strong> {eventDetails.PLZ}</Text>
          </Col>}
          {eventDetails.Stadt &&
            <Col xs={12} sm={12}><Text><strong>Stadt:</strong> {eventDetails.Stadt}</Text></Col>
          }



{eventDetails.Fortbildungspunkte && <Col xs={24} sm={24}><Text><strong>Akkreditierung:</strong></Text>
          {eventDetails.Fortbildungspunkte.map((point, index) => (

            <div key={index}>
              <Text> {point.Punkte} Punkte von {point.Vergabestelle}</Text>
            </div>
          ))}
        </Col>}


          {eventDetails.Themengebiete && Array.isArray(eventDetails.Themengebiete) && (
            <Col xs={24} sm={24}>
              <Text><strong>Themengebiete: </strong></Text>
              {(eventDetails.Themengebiete || []).map(theme => (
                <Tag key={theme}>{theme}</Tag>
              ))}
        </Col>
          )}


{ eventDetails.Anmeldelink && <Col xs={24} sm={24}>
<div style={{ width: '100%', textAlign: 'center' }}>
<Button  onClick={ () => window.open("https://" + eventDetails.Anmeldelink, '_blank')} >Zur Anmeldung</Button> </div>
</Col> }


      {eventDetails.ExternerLink && <div style={{ width: '100%', textAlign: 'center'}} >Mehr Informationen unter: <a href={"https://" + eventDetails.ExternerLink}>{"https://" + eventDetails.ExternerLink}</a> </div>}
      </Row>
    </div>
  );
};

const columnsExtended = [ ...columns,
  { title: 'ICW Zertifizierung', prop: 'ICW', render: (ICW) => <span>{ICW ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times"></i>}</span> },
  { title: 'DGfw Zertifizierung', prop: 'DGfw', render: (ICW) => <span>{ICW ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times"></i>}</span>  },
];


class SelectableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  };

  deSelectRow = (row) => {
    this.setState({ selected: '' });
  };

  buildRowOptions = (row) => {
    return {
//      onMouseOver: () => this.selectRow(row),
//      onMouseOut: () => this.deSelectRow(row),
      className: this.state.selected === row.id ? 'active-row' : null,
    };
  };

  render() {
    return (
      <DataTable buildRowOptions={this.buildRowOptions} {...this.props} />
    );
  }
}

class Veranstaltung extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      selected: ''
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  }

//  buildRowOptions = (row) => { alert(row.name);
//   return {
//     onClick: this.selectRow.bind(this, row),
//     className: this.state.selected === row.id ? 'active' : null
//   }}

 formatDate = (date) => {
      var date = new Date(date);

      // Extracting day, month, and year components
      var day = date.getDate();
      var month = date.getMonth() + 1; // Adding 1 as months are 0-based
      var year = date.getFullYear() - 2000; // Assuming you want to represen

      var formattedDate = (day > 9 ? day : ('0' + day)) + '.' +  (month > 9 ? month : ('0' + month))  + '.' + year;
      return <span>{formattedDate}</span>
  }

  render() {

  const { veranstaltungenData, match } = this.props;
  const vId = match.params.id;

  const foundEntity = veranstaltungenData.find( v => v.id === +vId );

   const formattedDateRange = [(foundEntity || {}).Startzeit, (foundEntity || {}).Endzeit].map(date => moment(date).format("DD.MM.YY HH:mm"));
//    ((foundEntity || {}).date || []).map(date => moment(date).format("DD.MM.YY HH:mm"));

  const dateRange =  <span>{formattedDateRange[0]} <i className="fas fa-arrow-right right-arrow dateArrow" /> {formattedDateRange[1]}</span>

  const referenten = [...(((foundEntity || {}).referents || {}).data || []), ...((foundEntity || {}).ExterneReferenten || [])] ;

  const referentendata = (referenten || []).filter(r => r.attributes ).map((d, i) =>  ({
            id : d.id,
            title: `${d.attributes.Vorname} ${d.attributes.Name}`,
            avatar: d.attributes.Bild ? API_BASE + d.attributes.Bild.data.attributes.url : "",
            description:
              d.attributes.Bio,
            content: <>
                <h4>Qualifikationen</h4>
                <p>
                    {([...(d.attributes.Zusatzqualifikation || []), ...(d.attributes.Grundqualifikation || [])]).map(theme => (
                      <Tag key={theme}>{theme}</Tag>
                    ))}
                </p>
            </>,
          }));

    const herstellers = [...(((foundEntity || {}).herstellers || {}).data || []), ...((foundEntity || {}).ExterneHersteller || [])] ;

    const herstellerdata = herstellers.filter(r => r.attributes ).map((d, i) =>  ({
          id : d.id,
          title: `${d.attributes.Herstellername}`,
          avatar: d.attributes.Logo ? API_BASE + d.attributes.Logo.data.attributes.url : "",
          description:
            d.attributes.Beschreibung,
          content: null,
        }));

    return foundEntity ? (
      <>
  <CitySection
  style={{ marginTop: -8 }}
      key={1}
      headline={<h2 style={{ textAlign: 'left', fontWeight: 400, marginBottom: 8 }}>{foundEntity.Veranstaltungsname}</h2>}
      description={<>
      <div className={"leafContainer leafContainer--veranstaltung"}>
      { (((foundEntity.Bild || {}).data || {}).attributes) && <img className={"leafImage leafImage--veranstaltung"} src={API_BASE + foundEntity.Bild.data.attributes.url}  />}
      <br/>
        <div className="row">
            <div className="col-md-20 veranstaltungen">
            <p className="leaf--p">
            <span className="leaf--title">Ort</span>
            <span className="leaf--value">{foundEntity.Stadt}</span>
            </p>
            <p className="leaf--p">
            <span className="leaf--title">Datum</span>
            <span className="leaf--value">{dateRange}</span>
            </p>
            <p className="leaf--p">
            <span className="leaf--title">Veranstalter</span>
            <span className="leaf--value"><span className="leaf--title--light">{foundEntity.Veranstaltername}</span></span>
            </p>
           {(foundEntity.Fortbildungspunkte || []).length > 0 && <p className="leaf--p">
            <span className="leaf--title">Fortbildungspunkte</span>
            <span className="leaf--value" style={{ marginTop: '1rem' }}>
                    <>
                        { (foundEntity.Fortbildungspunkte).map((f,i) => {

                            return  <Tag key={f.Vergabestelle}>{f.Vergabestelle} [{f.Punkte + " Punkte"}]</Tag>
                          }) }

                      </>
                      </span>
            </p> }
            </div>
        </div>
        </div>
        { ((foundEntity.Medien || {}).data || []).length > 0 && <Col xs={24} sm={24}>

                        <MedienCarousel overwriteTitle={"Galerie"} pickedData={foundEntity.Medien.data.map( el => el.attributes)} />
                  </Col> }
        <br />

        {foundEntity.Beschreibung && <Col xs={24} sm={24}>
            <div>
            <Divider orientation="left">Beschreibung</Divider>
                    <div className="edDrop" dangerouslySetInnerHTML={{ __html: foundEntity.Beschreibung }} />

                    </div>
                  </Col>}
        { referenten.length > 0 && <Col xs={18} sm={18}>
        <Divider orientation="left">Referenten auf der Veranstaltung</Divider>
            <List
                itemLayout="vertical"
                size="large"
                dataSource={referentendata}
                footer={
                   (referenten || []).filter(r => !r.attributes ).length > 0 ? <div>
                    <h4>Andere Referenten auf der Veranstaltung</h4>
                    <p>{referenten.filter(r => !r.attributes ).map(r => r.Name).join(", ")}</p>
                  </div> : null
                }
                renderItem={(item) => (
                  <Link to={"/referenten/" + item.id}><List.Item
                    key={item.title}
                    actions={[
                      <Link to={"/referenten/" + item.id}>Referent Profil auf PMP Online ansehen</Link>
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<Avatar size={128} src={item.avatar} />}
                      title={<a href={item.href}>{item.title}</a>}
                      description={
                      <div className="edDrop edLimited" dangerouslySetInnerHTML={{ __html: item.description }} />
                      }
                    />
                    {item.content}
                  </List.Item></Link>
                )}
              /> </Col>

         }
         { herstellers.length > 0 && <Col xs={18} sm={18}>
                 <Divider orientation="left">Hersteller auf der Veranstaltung</Divider>
                     <List
                         itemLayout="vertical"
                         size="large"
                         dataSource={herstellerdata}
                         footer={
                          (herstellers || []).filter(r => !r.attributes ).length > 0 ? <div>
                             <h4>Andere Hersteller auf der Veranstaltung</h4>
                             <p>{herstellers.filter(r => !r.attributes ).map(r => r.Name).join(", ")}</p>
                           </div> : null
                         }
                         renderItem={(item) => (
                           <Link to={"/hersteller/" + item.id}><List.Item
                             key={item.title}
                             actions={[
                               <Link to={"/hersteller/" + item.id}>Hersteller Profil auf PMP Online ansehen</Link>
                             ]}
                           >
                             <List.Item.Meta
                               avatar={<Avatar shape="square" size={128} src={item.avatar} />}
                               title={<a href={item.href}>{item.title}</a>}
                               description={
                                                                 <div className="edDrop edLimited" dangerouslySetInnerHTML={{ __html: item.description }} />
                                                                 }
                             />
                             {item.content}
                           </List.Item></Link>
                         )}
                       /> </Col>

                  }
        <EventDetails eventDetails={(foundEntity || {})} />
        <br />
        <p className="full-desc">{foundEntity.description}</p>
        { foundEntity.Homepage && <p>
        <hr />
<br />
            <span className="leaf--v">Weitere Informationen:</span>
            <a target="_blank" href={"https://" + foundEntity.Homepage} className="row external__link">https://{foundEntity.Homepage}</a>
        </p>}

        </>
        }
    />
    <br />
      </>
    ) : <h2>Veranstaltung nicht gefunden</h2>;
  }
}
//        <Media />
//        <Collections collectionsData={collectionsData} />
const CitySection = ({ style, headline, description, cardsData }) => (
  <div style={style} className="city-section-wrapper">
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      {headline}
    </h2>
    <hr
      style={{
        backgroundColor: '#eb008c',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
      {description}
    </p>

    <div className="travel-card-wrapper">

    </div>
  </div>
);

export default withRouter(Veranstaltung);