import React from "react";
import { Link } from 'react-router-dom';
import { Avatar, List ,Button, message, Popconfirm, Card, Col, Form, Input, Row, Spin } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import { useState } from "react";
import { getToken } from "../../helpers";
import styled, { createGlobalStyle } from 'styled-components';
import { API_BASE } from "../../constant";

// Global style for antd
const GlobalStyle = createGlobalStyle`
  @import '~antd/dist/antd.css';
`;

const deleteItem = async (type,id) => {
  try {
        const response = await fetch(`${API}/${type.toLowerCase()}s/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            // set the auth token to the user's jwt
            Authorization: `Bearer ${getToken()}`,
          }
        });
        const responseData = await response.json();

          message.success({
            content: 'Inserat erfolgreich gelöscht',
            className: 'custom-class',
            style: {
              marginTop: '20vh',
            },
          });

          window.location.reload();

      } catch (error) {
        console.error(Error);
        message.error("Fehler beim Löschen des Inserats");
      }
};

const successDelete = () => {
  message.success({
    content: 'Inserat erfolgreich gelöscht',
    className: 'custom-class',
    style: {
      marginTop: '20vh',
    },
  });
};

const data = [
  {
    title: 'Ant Design Title 1',
  },
  {
    title: 'Ant Design Title 2',
  },
  {
    title: 'Ant Design Title 3',
  },
  {
    title: 'Ant Design Title 4',
  },
];

const VesteList = (props) => {
  const [loading, setLoading] = useState(false);
  const { user, isLoading, setUser } = useAuthContext();

  const handleListUpdate = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/users/${user.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();

      message.success("Daten erfolgreich gespeichert!");
    } catch (error) {
      console.error(Error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

console.debug(props.veranstaltungenData, (user || {}).id);

  return (
  <>
    <Card className="List_page_card">
      <List
          itemLayout="horizontal"
          dataSource={props.veranstaltungenData.filter((f,i) => f.createdby === user.id)}
          renderItem={(item) => {
          const resPath = item.type === "Veranstaltung" ? "veranstaltungen" : item.type === "Referent" ? "referenten" : item.type === "Versorgungspartner" ? "versorgungspartner" : item.type === "Hersteller" ? "hersteller" : "produkt";

          return <List.Item  actions={[ item.Published ? <Link
                to={{ pathname: `/${resPath}/${item.id}` }}
                key={'v_ha'}
                className="link_sa view-all"
              >Ansehen</Link> : null, <Link
                                               to={{ pathname: `/admin/aktionen/${item.type}/${item.id}` }}
                                               key={'v_ha_e'}
                                               className="link_sa view-all"
                                             >Bearbeiten</Link>,
            <Popconfirm
                title="Sind Sie sicher, dass Sie dieses Inserat löschen möchten?"
                onConfirm={() => deleteItem(item.type, item.id)}
                okText="Ja"
                cancelText="Nein"
              >
                <a href="#" style={{ color: 'red' }}>Löschen</a>
              </Popconfirm>
              ]}>

              <List.Item.Meta
                avatar={ (((item.Bild || item.Logo || {}).data || {}).attributes) ? <Avatar  shape={item.type === "Referent" ? "" : "square"} size={64} src={API_BASE + (item.Bild || item.Logo).data.attributes.url} /> : null}
                title={item.label || item.name}
                description={<>{item.type} | {item.Veranstaltungsname || item.Name} | {(item.Published ? <span style={{color: "#52c41a"}}>Veröffentlicht</span> : <span style={{color: "#ff4d4f"}}>Nicht veröffentlicht</span>)} </>}
              />
            </List.Item>
          }}
        />
    </Card>
    </>
  );
};

export default VesteList;
