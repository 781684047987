
import React, { useEffect, useState } from 'react';

import AuthProvider from "./components/AuthProvider/AuthProvider";
import Header from './Components/Header';
import Footer from './Components/footer';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MainHome from './Components/MainHome';
import NewYork from './Components/NewYork';
import Veranstaltungen from './Components/Veranstaltungen';
import Veranstaltung from './Components/Veranstaltung';
import Referenten from './Components/Referenten';
import Versorgungspartner from './Components/Versorgungspartner';
import VersorgungspartnerLeaf from './Components/Versorgungspartner-leaf';
import Referent from './Components/Referent';
import Info from './Components/Info';
import Erfolg from './Components/Erfolg';
import Plans from './Components/Plans';
import Datenschutz from './Components/Datenschutz';
import Benutzerkonto from './Components/Benutzerkonto';
import MeineInserate from './Components/Meine-Inserate';
import ProfilAnerkennungProfile from "./components/ProfilAnerkennungProfile";
import Inserieren from './Components/Inserieren';
import SignInUp from './Components/SignInUp';
import Impressum from './Components/Impressum';
import Hersteller from './Components/Hersteller';
import HerstellerLeaf from './Components/Hersteller-Leaf';
import Produkt from './Components/Produkt';
import AppStore from './Components/AppStore';
import Profile from "./components/Profile/Profile";
import { API } from "./constant";
import { getToken, removeToken, getUserE, getExpire } from "./helpers";

import { useAuthContext } from "./context/AuthContext";


// Caraousel Images for Home

const backgroundImagesData = [
  {
    id: 1,
    url: '/pmp-header-1.jpg',

  },
  {
    id: 2,
    url: '/pmp-header-2.jpg',

  },
  {
    id: 3,
    url: '/pmp-header-3.jpg',

  },
  {
    id: 4,
    url: '/pmp-header-4.jpg',

  }
];

// Header Navigation Data

const HeaderNavDataC1 = [
   {
            id: 0,
            name: 'Suchen',
            url: ''
          },
        {
          id: 1,
          name: 'Veranstaltungen',
           url: 'veranstaltungen'
        },
        {
          id: 2,
          name: 'Referenten',
         url: 'referenten'
        },
        {
          id: 3,
          name: 'Hersteller/ Produkte',
         url: 'hersteller'
        }
];
const HeaderNavDataC2 = [
        {
          id: 4,
          name: 'Versorgungspartner',
         url: 'versorgungspartner'
        },
     {
       id: 5,
       name: 'Über ICW Portal',
      url: 'info'
     }
];

const HeaderNavData = [
];



const PageRoutes = () => {
  const { user, sub, setUser } = useAuthContext();
  const [veranstaltungenData, setVeranstaltungenData] = useState([]);
  const [filterAdvancedHighlighted, setFilterAdvancedHighlighted] = useState(true);


  useEffect(() => {
    // This code will be executed after the component is mounted to the DOM

    // You can perform actions, such as data fetching or initialization, here
    // If you want to perform cleanup when the component is unmounted, return a function from useEffect
    return () => {
      window.scrollTo(0, 0);
      window.onscroll = function() {
          // Get the header
          var header = document.getElementsByClassName("search-bar-div")[0];
          var topSearch = document.getElementById("topSearch");

          // Get the offset position of the navbar
          if (header) {
                var sticky = header.offsetTop - 100;
                if (window.pageYOffset > sticky) {
                  topSearch.style.opacity = "1";
                } else {
                  topSearch.style.opacity = "0";
                }
          }
      };
    };



  }, []);

    return(
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <div className="App">
              <Header
                setVeranstaltungenData={setVeranstaltungenData}
                backgroundImagesData={backgroundImagesData}
                navigationData={HeaderNavData}
                navigationDataC1={HeaderNavDataC1}
                navigationDataC2={HeaderNavDataC2}
                setFilterAdvancedHighlighted={setFilterAdvancedHighlighted}
                filterAdvancedHighlighted={filterAdvancedHighlighted}
                veranstaltungenData={veranstaltungenData}
              />
              <div style={{ background: (user || {}).Rolle === "ICW-Admin" ? "#ffe0edb0" : "initial" }} className="contentWrap">
                  <Route exact path="/" render={(props) => <MainHome veranstaltungenData={veranstaltungenData}  additionalProp="value" />} />
                  <Route exact path="/admin/aktionen/:type" render={(props) => <Inserieren veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/admin/aktionen/:type/:id" render={(props) => <Inserieren veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/admin/meine-inserate" render={(props) => <MeineInserate veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/admin/benutzerkonto" render={(props) => <Benutzerkonto veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/admin/aktionen" render={(props) => <Plans veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/anmelden" render={(props) => <SignInUp veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/signup" render={(props) => <SignInUp veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/password-reset" render={(props) => <SignInUp veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/password-new" render={(props) => <SignInUp veranstaltungenData={veranstaltungenData}  />} />
                  <Route exact path="/p-success" component={Erfolg} />
                  <Route exact path="/app" component={AppStore} />
                  <Route exact path="/info" component={Info} />
                  <Route exact path="/datenschutz" component={Datenschutz} />
                  <Route exact path="/impressum" component={Impressum} />
              </div>
              <Footer />
          </div>
        </Switch>
      </BrowserRouter>
    </AuthProvider>
    )
};

export default PageRoutes;
