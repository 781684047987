import React, { Component } from 'react';
import { AUTH_TOKEN, EXPIRE_DATE, USER_E } from "./constant";
import { Typography, List, Tag } from 'antd';
const { Text, Title } = Typography;

//import {pdfform } from "./pdfform.pdf_js.dist.js";
//const pdf = require("./pdfform.minipdf.dist");

export const getToken = () => {
  return  localStorage.getItem(AUTH_TOKEN);//  "dummyToken"; // localStorage.getItem("dummyToken"); // localStorage.getItem(AUTH_TOKEN);
};

export const formatChanges = (revisionObj, formConfig) => {

    return <>
                       {revisionObj.reverse().map((revision, index) => {

                       const dateObj = new Date(revision.date);

                       // Format the date in German
                       const germanDate = dateObj.toLocaleString('de-DE', {
                           year: 'numeric', // Full year
                           month: 'long', // Full month name
                           day: 'numeric', // Day of the month
                           hour: '2-digit', // Two-digit hour
                           minute: '2-digit', // Two-digit minute
                           second: '2-digit', // Two-digit second
                       });

                       return (
                           <div key={index} style={{ marginBottom: '24px' }}>
                               {/* Timestamp Title */}
                               <Title level={4} style={{ color: '#eb008c' }}>
                                   Änderungen vom {germanDate} von <span style={{ color: '#6700eb' }}>{JSON.stringify(revision.author || {})}</span> :
                               </Title>
                               <List
                                   bordered
                                   dataSource={Object.keys(revision.changes)}
                                   renderItem={(key) => {
                                       const change = revision.changes[key];
                                       const keyBefore = (key || "").replace("dozentinformation.", "").replace("profilinformation.", "").replace("kursinformation.", "").replace(".0", "").replace(".1", "").replace(".2", "").replace(".3", "")
                                       const relevantKids = formConfig.map(f => f.forms).flat();
                                       return (
                                           <List.Item>
                                               <Text style={{ minWidth: 200 }} strong>{(relevantKids.find(s => s.key === keyBefore) || {}).label || keyBefore}</Text>
                                               <br />
                                               {
                                                   <div>
                                                       <Tag color="red">
                                                           Vorher: {change.before || " -"}
                                                       </Tag>
                                                   </div>
                                               }
                                               {
                                                   <div>
                                                       <Tag color="green">
                                                           Nachher: {change.after || " -"}
                                                       </Tag>
                                                   </div>
                                               }
                                           </List.Item>
                                       );
                                   }}
                               />
                           </div>
                       )})}
                   </>
}

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export function calculateDistance(l1, l2) {
    const [lat1, lon1] = l1;
    const [lat2, lon2] = l2;

    const R = 6371e3; // Earth radius in meters
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2-lat1) * Math.PI / 180;
    const Δλ = (lon2-lon1) * Math.PI / 180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    const d = R * c; // Distance in meters
    return d;
}

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const getExpire = () => {
  return  localStorage.getItem(EXPIRE_DATE);//  "dummyToken"; // localStorage.getItem("dummyToken"); // localStorage.getItem(AUTH_TOKEN);
};

export const setExpire = (expire) => {
  if (expire) {
    localStorage.setItem(EXPIRE_DATE, expire);
  }
};

export const removeExpire = () => {
  localStorage.removeItem(EXPIRE_DATE);
};


export const getUserE = () => {
  return  localStorage.getItem(USER_E);
};

export const setUserE = (expire) => {
  if (expire) {
    localStorage.setItem(USER_E, expire);
  }
};

export const removeUserE = () => {
  localStorage.removeItem(USER_E);
};

/*

// Example of constructing pdfform
// If you don't care about which PDF library to use, just call without arguments, as in
// pdfform().transform(..) / pdfform().list_fields(...)
export function make_pdfform() {
	var lib_name = document.querySelector('input[name="pdflib"]:checked').value;
	return pdfform((lib_name === 'minipdf') ? minipdf : minipdf_js);
}

// Example of listing all fields
export function list(buf) {
	var list_form = document.querySelector('.list_form');
	empty(list_form);

	var cnt = 1;
	var field_specs;
	try {
		field_specs = make_pdfform().list_fields(buf);
	} catch (e) {
		on_error(e);
		return;
	}
	for (var field_key in field_specs) {
		var row = document.createElement('div');
		row.appendChild(document.createTextNode(field_key));
		list_form.appendChild(row);
		field_specs[field_key].forEach(function(spec, i) {
			if ((spec.type === 'radio') && spec.options) {
				var fieldset_el = document.createElement('fieldset');
				spec.options.forEach(function(ostr) {
					var label = document.createElement('label');
					var radio = document.createElement('input');
					radio.setAttribute('type', 'radio');
					radio.setAttribute('value', ostr);
					radio.setAttribute('name', field_key + '_' + i);
					radio.setAttribute('data-idx', i);
					radio.setAttribute('data-key', field_key);
					label.appendChild(radio);
					label.appendChild(document.createTextNode(ostr));
					fieldset_el.appendChild(label);
				});
				row.appendChild(fieldset_el);
				return;
			}

			var input = document.createElement((spec.type === 'select') ? 'select' : 'input');
			input.setAttribute('data-idx', i);
			input.setAttribute('data-key', field_key);
			if (spec.type === 'boolean') {
				input.setAttribute('type', 'checkbox');
			} else if (spec.type === 'string') {
				input.setAttribute('value', cnt++);
			} else if ((spec.type === 'select') && spec.options) {
				spec.options.forEach(function(ostr) {
					var option_el = document.createElement('option');
					option_el.appendChild(document.createTextNode(ostr));
					option_el.setAttribute('value', ostr);
					input.appendChild(option_el);
				});
			}
			row.appendChild(input);
		});
	}
}

// Example of filling out fields
export function fill(buf) {
	var list_form = document.querySelector('.list_form');
	var fields = {};
	list_form.querySelectorAll('input,select').forEach(function(input) {
		if ((input.getAttribute('type') === 'radio') && !input.checked) {
			return;
		}

		var key = input.getAttribute('data-key');
		if (!fields[key]) {
			fields[key] = [];
		}
		var index = parseInt(input.getAttribute('data-idx'), 10);
		var value = (input.getAttribute('type') === 'checkbox') ? input.checked : input.value;
		fields[key][index] = value;
	});

	var filled_pdf; // Uint8Array
	try {
		filled_pdf = make_pdfform().transform(buf, fields);
	} catch (e) {
		return on_error(e);
	}

	var blob = new Blob([filled_pdf], {type: 'application/pdf'});
	saveAs(blob, 'pdfform.js_generated.pdf');
}


// From here on just code for this demo.
// This will not feature in your website
export function on_error(e) {
	console.error(e, e.stack);  // eslint-disable-line no-console
	var div = document.createElement('div');
	div.appendChild(document.createTextNode(e.message));
	document.querySelector('.error').appendChild(div);
}

export function empty(node) {
	var last;
	while ((last = node.lastChild)) {
		node.removeChild(last);
	}
}

var current_buffer;

export function on_file(filename, buf) {
	current_buffer = buf;
	document.querySelector('.url_form').setAttribute('style', 'display: none');
	var cur_file = document.querySelector('.cur_file');
	empty(cur_file);
	cur_file.setAttribute('style', 'display: block');
	cur_file.appendChild(document.createTextNode('loaded file ' + filename + ' (' + buf.byteLength + ' Bytes)'));
	var reload_btn = document.createElement('button');
	reload_btn.appendChild(document.createTextNode('use another file'));
	cur_file.appendChild(reload_btn);
	document.querySelector('.fill').removeAttribute('disabled');

	list(current_buffer);
}*/
