import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import Profile from "../components/Profile/Profile";
import SignIn from "../pages/SignIn/SignIn";
import SignUp from "../pages/SignUp/SignUp";
import PasswordNew from "../pages/PasswordNew/PasswordNew";
import PasswordReset from "../pages/PasswordReset/PasswordReset";
import { getToken } from "../helpers";
import './Styles/new-york.css';

class SignInUp extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    const token = getToken();

//    if (!token) {
//        return this.props.history.push(`/anmelden`);
//    }

    const isSignIn = this.props.history.location.pathname === '/anmelden';
    const isPasswordReset = this.props.history.location.pathname === '/password-reset';
    const isPasswordNew = this.props.history.location.pathname === '/password-new';

    // /anmelden

    return (
      <>
<div className="city-section-wrapper static-padding"><br />
    <h2
      style={{
        textAlign: 'left', marginBottom: 8, marginTop: 64
      }}
    >
      { isPasswordNew ? "Neues Passwort" : (isPasswordReset ? "Passwort vergessen" : (isSignIn ? "" : ""))  }
    </h2>
    { (isPasswordReset || isPasswordNew) && <hr
      style={{
        backgroundColor: '#eb008c',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    /> }
    { isPasswordNew ? <PasswordNew /> : isPasswordReset ? <PasswordReset /> : isSignIn ? <SignIn /> : <SignUp />  }
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
    </p>
    <div className="travel-card-wrapper">

    </div>
  </div>

      </>
    );
  }
}

export default withRouter(SignInUp);
