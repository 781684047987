import React, { Component, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  Alert,
  Button,
  Card,
  Col,Flex,
  Form,
  Input,
  message,
  Row,List,
  Spin,Popconfirm,
  Typography,
} from "antd";
import { InboxOutlined, UploadOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { API, STRAPI_API, BEARER } from "../constant";
import { AuthContext, useAuthContext } from "../context/AuthContext";
import { getToken, removeExpire, removeToken } from "../helpers";

const StatusEnum = {
  NOT_SUBMITTED: "Noch nicht eingereicht",
  SUBMITTED_REJECTED: "Eingereicht - abgelehnt",
  SUBMITTED_ACCEPTED: "Eingereicht - angenommen",
  SUBMITTED_IN_PROGRESS: "Eingereicht - in Bearbeitung"
};

const YourComponent = (props) => {
  const { user, setUser } = useAuthContext();
  const isICW = (user || {}).Rolle === "ICW-Admin";
  const isAnbieter = (user || {}).Rolle === "Anbieter";
  const isAuditor = (user || {}).Rolle === "Auditor";

  const foundProfil = props.veranstaltungenData.find((f,i) => f.type === (isAuditor ? 'audit-bogen' : 'anbieter-profil'));


  const isAnerkannt = isAuditor || !!(foundProfil && ((foundProfil.status === StatusEnum.SUBMITTED_ACCEPTED) || (isAuditor && foundProfil.Kursnummer)));


  console.log(isAnerkannt, '! user', user);

  if ((user || {}).confirmed === false) {
    message.info("Bitte bestätigen Sie Ihre E-Mail-Adresse, um fortzufahren.");
      removeToken();
      removeExpire();
      setUser(null);
    props.history.push("/anmelden");
  }

  if (isAuditor) {
    props.history.push("/admin/aktionen/Seminare", { replace: true });
  }

  // on mount redirect to /admin/aktionen/Seminare if user is auditor

  useEffect(() => {
      if (user !== null && (user || {}).Rolle === "Auditor") {
//        props.history.push("/admin/aktionen/Seminare", { replace: true });
      }
  }, [user]);

  console.debug("plans", props, foundProfil);
  
  const Anerkennungsantrag = isAuditor ? "Auditor Bericht" : "Anerkennungsantrag";

    return (
      <>
      <div className="city-section-wrapper static-padding"><br /><br /><br /><br />
          <h2
            style={{
              textAlign: 'left', marginBottom: 8
            }}
          >
            Aktionen
          </h2>

          <hr
            style={{
              backgroundColor: '#eb008c',
              width: '75px',
              height: '2px',
              border: 'none',
              marginTop: '0px',
              marginLeft: '0px',
              marginBottom: '24px'
            }}
          />
         <Row gutter={[16, 16]}>
              <Col xl={8} sm={24} xs={24}>
                  <Card title={<Button onClick={() => props.history.push(isICW ? `/admin/aktionen/Anbieterverwaltung` : isAuditor ? `/admin/aktionen/Auditbericht/${(foundProfil || {}).id || ""}` : `/admin/aktionen/Profil/${(foundProfil || {}).id || ""}`) }  type="primary" size="large" ghost>
                  { isICW && "Anbieter anerkennen" }
                  { !isICW && (!foundProfil) && <span>{Anerkennungsantrag} starten...</span> }
                  { !isICW && ((foundProfil && null === foundProfil.status)) && <span>{Anerkennungsantrag} starten...</span> }
                  { !isICW && ((foundProfil && StatusEnum.NOT_SUBMITTED === foundProfil.status)) && <span>{Anerkennungsantrag} fortführen...</span> }
                  { !isICW && foundProfil && StatusEnum.SUBMITTED_REJECTED === foundProfil.status && <span>{Anerkennungsantrag} aktualisieren</span> }
                  { !isICW && foundProfil && StatusEnum.SUBMITTED_ACCEPTED === foundProfil.status && <span>{Anerkennungsantrag} aktualisieren</span> }
                  { !isICW && foundProfil && StatusEnum.SUBMITTED_IN_PROGRESS === foundProfil.status && <span>{Anerkennungsantrag} aktualisieren</span> }
                                             </Button>} bordered={false}>

                                             { !isICW && foundProfil && foundProfil.updatedAt && <p>Letztes Update: {moment(foundProfil.updatedAt).format("DD.MM.YY HH:mm")}</p>}

                                             { !isICW && (!foundProfil) && <p><Alert message={"Noch nicht gestartet"} type={"info"}  /></p>}
                                             { !isICW && foundProfil && foundProfil.status && <p><Alert message={foundProfil.status} type={(
                                             StatusEnum.NOT_SUBMITTED === foundProfil.status ? "info" :
                                             StatusEnum.SUBMITTED_IN_PROGRESS === foundProfil.status ? "warning" :
                                             StatusEnum.SUBMITTED_REJECTED === foundProfil.status ? "error" :
                                             StatusEnum.SUBMITTED_ACCEPTED === foundProfil.status ? "success" : "info")}  />
                                            </p>}
                                            { isICW && <p> Hier können Sie Anbieteranträge einsehen und anerkennen.</p>}
                                            { !isICW && foundProfil && foundProfil.kommentar && StatusEnum.SUBMITTED_REJECTED === foundProfil.status && <p style={{textAlign: 'left'}}>Grund: <div dangerouslySetInnerHTML={{ __html: foundProfil.kommentar }}></div></p>}
                  </Card>
                </Col>
                { !isAuditor && <Col xl={8} sm={24} xs={24}>
                  <Card title={<Button onClick={() => isICW ? props.history.push(`/admin/aktionen/Seminarverwaltung`) : props.history.push(`/admin/aktionen/Dozenten`) } disabled={!isICW && !isAnerkannt} type="primary" size="large" ghost>
                                               { isICW ? "Seminare genehmigen" : "Dozenten verwalten" }
                                             </Button>} bordered={false}>
                    <p>{ isICW ? "Hier können Sie Seminaranträge einsehen und genehmigen." : !isAnerkannt ? "Bitte reichen Sie zuerst Ihren Anerkennungsantrag ein." : "Hier können Sie Dozenten hinzufügen, entfernen und aktualisieren."}</p>
                  </Card>
                </Col>}
                <Col xl={8} sm={24} xs={24}>
                  <Card title={<Button onClick={() => isICW ? props.history.push(`/admin/aktionen/Dozentenverwaltung`) : props.history.push(`/admin/aktionen/Seminare`) } disabled={!isAnerkannt && !isICW} type="primary" size="large" ghost>
                                               { isICW ? "Dozenten freischalten" : isAuditor ? "Zu auditierende Seminar..." : "Seminargenehmigung beantragen" }
                                             </Button>} bordered={false}>
                    <p>{ isICW ? "Bevor Anbieter Dozenten zu ihren Seminaren hinzufügen können, muesse sie freigeschaltet werden." : isAuditor ? "Hier können Sie Seminare anzeigen, die für Sie zur Auditierung freigeschaltet wurden..." : !isAnerkannt ? "Bitte reichen Sie zuerst Ihren Anerkennungsantrag ein." : "Hier können Sie Seminare beantragen und vergangene Seminare einsehen."}</p>
                  </Card>
                </Col>
                { isICW && <Col xl={8} sm={24} xs={24}>
                  <Card title={<Button onClick={() => isICW ? props.history.push(`/admin/aktionen/Auditorverwaltung`) : props.history.push(`/admin/aktionen/Seminare`) } disabled={!isAnerkannt && !isICW} type="primary" size="large" ghost>
                                               { isICW ? "Audit beauftragen" : isAuditor ? "Zu auditierende Seminare..." : "Seminargenehmigung beantragen" }
                                             </Button>} bordered={false}>
                    <p>{ isICW ? "Hier können Sie Auditoren Leserechte von speziellen Seminaren und Anbietern geben und Auditberichte verwalten." : isAuditor ? "Hier können Sie Seminare anzeigen, die für Sie zur Auditierung freigeschaltet wurden..." : !isAnerkannt ? "Bitte reichen Sie zuerst Ihren Anerkennungsantrag ein." : "Hier können Sie Seminare beantragen und vergangene Seminare einsehen."}</p>
                  </Card>
                </Col>}
              </Row>
          <p
            style={{
              color: '#545454',
              fontSize: '15.3px',
              marginTop: '0px',
              textAlign: 'left',
              lineHeight: '20px'
            }}
          >
          </p>
          <div className="travel-card-wrapper">

          </div>
        </div>

            </>
          );
}

export default withRouter(YourComponent);
