import React, { useState, useEffect } from 'react';
import { Rate, List,Checkbox, Row, Col, Button, Modal, Input, Form, Switch, Select, Alert, Card, message, Radio, Tabs, Tag } from 'antd';
import { EnterOutlined, EyeOutlined, DeleteOutlined, PlusOutlined, CommentOutlined, CloseCircleOutlined, HistoryOutlined, CheckCircleOutlined} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { API } from "../constant";
import { formConfig } from "../configs/DozentAnerkennung";
import { getToken, formatChanges } from "../helpers";

const { Option } = Select;

const StatusEnum = {
  NOT_SUBMITTED: 'Noch nicht eingereicht',
  SUBMITTED_IN_PROGRESS: 'Eingereicht - in Bearbeitung',
  SUBMITTED_REJECTED: 'Eingereicht - abgelehnt',
  SUBMITTED_ACCEPTED: 'Eingereicht - angenommen',
};

const DozentenList = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isKommentarModalVisible, setIsKommentarModalVisible] = useState(false);
    const [isRevision, setIsRevision] = useState(false);
  const [currentDozent, setCurrentdozent] = useState(null);
  const [showAll, setShowAll] = useState(false)
  const [form] = Form.useForm();

  const [currSearch, setCurrSearch] = useState("");
  const [currFilter, setCurrFilter] = useState(StatusEnum.SUBMITTED_IN_PROGRESS);
  const [open, setOpen] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);

  const location = useLocation();
  useEffect(() => {
   if (
     location.state
   ) {
        if (location.state && location.state.open) {
          setOpen(location.state.open);
        }

        if (location.state && location.state.openApprove) {
          setOpenApprove(location.state.openApprove);
        }
    }
    // Update the ref with the current location state
  }, [location.pathname]);

  const showModal = (id) => {
    setOpen(id);
  };

    const showModalApprove = (id) => {
      setOpenApprove(id);
    };

  const handleOk = () => {
    setOpen(false);
  };
  const handleApproveOk = () => {
    setOpenApprove(false);
  };
  const handleSearchChange = (e) => {
    setCurrSearch(e.target.value);
  };

  const handleApproveCancel = () => {
    setOpenApprove(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const dozente = props.veranstaltungenData;
  const anbieter =  props.anbieterData;
  const showEditModal = (dozent) => {
    setCurrentdozent(dozent);
    setIsModalVisible(true);
    form.setFieldsValue(dozent);
  };

  const showKommentarModal = (dozent,isRevision) => {
    setCurrentdozent(dozent);
    setIsRevision(!!isRevision);
    setIsKommentarModalVisible(true);
  };

  const handleEdit = (dozentid) => {
   props.history.push(`/admin/aktionen/Dozenten/${dozentid}`)
  };

  const handleDeny = (id, kommentar = "") => {
        fetch(`${API}/dozents/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
              body: JSON.stringify({ data: { kommentar: kommentar, status: "Eingereicht - abgelehnt" }}),
            })
              .then(response => response.json())
              .then(data => { console.log(data);
                  if (data.error) {
//                              message("", "Es ging etwas schief");
                  } else {
//                              message("", "dozent gelöscht. .");

props.history.push('/admin/aktionen/Dozentenverwaltung', {openApprove: false, open: false })
                      window.location.reload();
                  }
              })
              .catch((error) => { console.error('Error:', error);
//                       message("", "Es ging etwas schief");
               });
      };

  const handleApprove = (id, anbieternummer, Fortbildungspunkte) => {

 fetch(`${API}/dozents/${id}`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                  },
                    body: JSON.stringify({ data: {dozentnummer: anbieternummer, Fortbildungspunkte: Fortbildungspunkte, status: "Eingereicht - angenommen"} }),
                })
                      .then(response => response.json())
                      .then(data => { console.log(data);
                          if (data.error) {
//                              message("", "Es ging etwas schief");
                          } else {
//                              message("", "dozent gelöscht. .");

props.history.push('/admin/aktionen/Dozentenverwaltung', {openApprove: false, open: false })
                      window.location.reload();
                          }
                      })
                      .catch((error) => { console.error('Error:', error);
//                       message("", "Es ging etwas schief");
                       });
  };
  const handleModeChange = (e) => {
    setCurrFilter(e.target.value);
  };

  const handleAdd = () => {
//    const newdozent = { id: Date.now(), name: '', status: StatusEnum.NOT_SUBMITTED, kommentar: '', updatedAt: moment().toISOString() };
//    setdozente([...dozente, newdozent]);
//    showEditModal(newdozent);
  };

  const sortedDozenten = dozente.filter(dozent => currFilter === "" || dozent.status === currFilter)
                           .filter(dozent => currSearch === "" || JSON.stringify(dozent || {}).toLowerCase().includes((currSearch || "").toLowerCase()))
                           .sort((a, b) => ((a || {}).dozentinformation || {})["Name"] > ((b || {}).dozentinformation || {})["Name"] ? 1 : -1)

  return (
    <div>
    <div style={{textAlign: 'left' }}>
       <Row gutter={[16, 16]}>
                         <Col md={8} lg={8} sm={24} xs={24}>
           <Input  onChange={handleSearchChange} style={{minWidth: 260 }} placeholder="Filter Anträge..." />
                      </Col><Col md={12} lg={12} sm={24} xs={24}>
            <Radio.Group
                        onChange={handleModeChange}
                        value={currFilter}
                        style={{
                          marginBottom: 8,
                        }}
                      >
                        <Radio.Button value="">Alle Anträge anzeigen</Radio.Button>
                        <Radio.Button value={StatusEnum.SUBMITTED_IN_PROGRESS}>Offene</Radio.Button>
                        <Radio.Button value={StatusEnum.SUBMITTED_REJECTED}>Abgelehnt</Radio.Button>
                        <Radio.Button value={StatusEnum.SUBMITTED_ACCEPTED}>Akzeptiert</Radio.Button>
                      </Radio.Group>
                      </Col>
                      </Row>
                      </div>


      <List
        dataSource={sortedDozenten.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))}
        renderItem={(dozent, i) => {
        console.debug(dozent, i, "TT");

        const createdByAnbieter = anbieter.find(a => a.createdby === dozent.createdby);

        const prevName = sortedDozenten[i - 1] ? ((sortedDozenten[i - 1] || {}).dozentinformation || {})["Vorname"] + ' ' + ((sortedDozenten[i - 1] || {}).dozentinformation || {})["Name"] : "";
        let fullName = ((dozent || {}).dozentinformation || {})["Vorname"] + ' ' + ((dozent || {}).dozentinformation || {})["Name"];
        const isPrev = fullName === prevName;
        if (isPrev) {
            fullName = <EnterOutlined  style={{ fontSize: 32, transform: 'scaleX(-1)' }} />;
        }

        return (
          <Card
            bordered={false}
            style={{ paddingTop: 0, background: isPrev ? '#ffcfec' : 'inherit', marginTop: isPrev ? -36 : 0, marginBottom: 16 }}
          >
            <List.Item
              style={{ paddingTop: 0 }}
              actions={[
                <Button icon={<EyeOutlined />}
                  onClick={() => props.history.push(`/admin/aktionen/Dozenten/${dozent.id}`)   }
                 >Anzeigen</Button>,
                dozent.status === StatusEnum.SUBMITTED_IN_PROGRESS ? <Button icon={<CloseCircleOutlined />} danger onClick={() => showModal(dozent.id)}>Ablehnen</Button> : null,
                dozent.status === StatusEnum.SUBMITTED_IN_PROGRESS ? <Button icon={<CheckCircleOutlined />} style={{ color: 'white', background: '#52c41a' }} onClick={() => showModalApprove(dozent.id)}>Genehmigen</Button> : null,
              ]}
            >
              <List.Item.Meta title={<><span style={{ paddingLeft: isPrev ? 64 : 'inherit', color: '#eb008c' }}>{fullName}</span> {(((createdByAnbieter || {}).profilinformation || {}).Institut ? (" (" + (createdByAnbieter || {}).profilinformation.anbieternummer + ")") : "")} </>} />

<List.Item.Meta title={

[...(((dozent || {}).dozentinformation || {})["Fortbildungsinhalte Ärztlicher Wundexperte ICW"] || []),
                                      ...(((dozent || {}).dozentinformation || {})["Fortbildungsinhalte Fachtherapeut Wunde ICW"] || []),
                                      ...(((dozent || {}).dozentinformation || {})["Fortbildungsinhalte Pflegetherapeut Wunde ICW"] || []),
                                      ...(((dozent || {}).dozentinformation || {})["Fortbildungsinhalte Wundexperte ICW"] || []), ...(((dozent || {}).dozentinformation || {}).AndererEinsatzSeminarthema || "").split(", ")].filter(f => f !== "").map(theme => (
                                                  <Tag key={theme}>{theme}</Tag>
                                                ))} />

              <List.Item.Meta style={{ paddingLeft: 4, paddingRight: 12 }}  title={<Alert
                              message={<span>{dozent.status} {(dozent.status === StatusEnum.SUBMITTED_REJECTED && (
                                     <Button icon={<CommentOutlined />} onClick={() => showKommentarModal(dozent)}>Kommentar</Button>
                                   ))}
                                   {props.isICW && (((dozent || {}).dozentinformation || {}).revision && <Button icon={<HistoryOutlined />} onClick={() => showKommentarModal(dozent, true)}>Änderungen</Button>)}
                                    {(dozent.Fortbildungspunkte && dozent.status === StatusEnum.SUBMITTED_ACCEPTED && (<p>
                                                                         <span>Fortbildungspunkte:</span> <span>{dozent.Fortbildungspunkte}</span></p>
                                                                       ))}
                                                                       </span>}
                              type={
                                dozent.status === StatusEnum.NOT_SUBMITTED
                                  ? 'info'
                                  : dozent.status === StatusEnum.SUBMITTED_IN_PROGRESS
                                  ? 'warning'
                                  : dozent.status === StatusEnum.SUBMITTED_REJECTED
                                  ? 'error'
                                  : 'success'
                              }
                            /> } />
                <List.Item.Meta title={dozent.updatedAt && <span>Letztes Update: {moment(dozent.updatedAt).format('DD.MM.YY HH:mm')}</span>} />
            </List.Item>
          </Card>
        )}}
  locale={{ emptyText: 'Keine Anträge gefunden' }}
      />
      <Modal
        title="Dozent bearbeiten"
        visible={isModalVisible}
        onOk={handleEdit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Bitte geben Sie einen Namen ein' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Bitte wählen Sie einen Status aus' }]}
          >
            <Select>
              <Option value={StatusEnum.NOT_SUBMITTED}>Anerkennung nicht gestartet</Option>
              <Option value={StatusEnum.SUBMITTED_IN_PROGRESS}>Anerkennung in Bearbeitung</Option>
              <Option value={StatusEnum.SUBMITTED_REJECTED}>Anerkennung abgelehnt</Option>
              <Option value={StatusEnum.SUBMITTED_ACCEPTED}>Anerkannt</Option>
            </Select>
          </Form.Item>
          {form.getFieldValue('status') === StatusEnum.SUBMITTED_REJECTED && (
            <Form.Item
              name="kommentar"
              label="Kommentar"
              rules={[{ required: true, message: 'Bitte geben Sie einen Kommentar ein' }]}
            >
              <Input.TextArea />
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        title={ isRevision ? "Verlauf" : "Kommentar" }
        visible={isKommentarModalVisible}
        width={ isRevision ? "85vw" : "50vw" }
        onCancel={() => setIsKommentarModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsKommentarModalVisible(false)}>
            Schließen
          </Button>,
        ]}
      >
         { isRevision ? formatChanges(((currentDozent || {}).dozentinformation || {}).revision, formConfig) : <div dangerouslySetInnerHTML={{ __html: currentDozent ? currentDozent.kommentar : '' }} />}
      </Modal>

      <Modal
                style={{ minWidth: 600 }}
                closable={true}
                open={openApprove}
                title="Dozent genehmigen"
                onOk={handleApproveOk}
                onCancel={handleApproveCancel}
                footer={null}
              > <Form
                   name="nest-messages"
                   onFinish={(values) => {
                      console.debug("values", values);
                      handleApprove(openApprove, values.dozentnummer, values.Fortbildungspunkte);
                  }}
                   style={{ maxWidth: 600 }}
                 >

                   <Form.Item wrapperCol={{ span: 16 , offset: 8 }}>
                   <Button type="primary" htmlType="submit" icon={<CheckCircleOutlined />} style={{ color: 'white', background: '#52c41a' }}>
                   Genehmigen</Button>
                   </Form.Item>
                 </Form>
              </Modal>

      <Modal
          closable={true}
          open={open}
          title="Begründung der Ablehnung"
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        > <Form
             name="nest-messages"
             onFinish={(values) => {
                console.debug("values", values);
                handleDeny(open, values.kommentar);
            }}
             style={{ maxWidth: 600 }}
           >
             <Form.Item name={'kommentar'} label="Begründung">
               <Input.TextArea />
             </Form.Item>
             <Form.Item wrapperCol={{ span: 16 , offset: 8 }}>
               <Button type="primary" htmlType="submit">
                 Mit obiger Begründung ablehnen
               </Button>
             </Form.Item>
           </Form>
        </Modal>
    </div>
  );
};

export default DozentenList;
