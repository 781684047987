import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { actions, Table, Pagination }  from 'react-data-components';
import 'react-data-components/css/table-twbs.css';
import MedienCarousel from './MedienCarousel';
import Autocomplete from './Autocomplete';
import DataTable from './Table/CustomDataTable';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';
import { API_BASE } from '../constant';
import PdfViewer from '../components/PdfViewer';
import { Typography, Divider, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

const columns = [
  { title: '', prop: 'image', render: (image) => <div className="city-card-img city-card-img--table" style={{ backgroundImage: `url(${image})` }}></div> },
  { title: 'Veranstaltung', prop: 'label', render : (label) => <span className="label">{label}</span> },
  { title: 'Datum', prop: 'date', sortable: true, render: (date) => {
  var date = new Date(date);

  // Extracting day, month, and year components
  var day = date.getDate();
  var month = date.getMonth() + 1; // Adding 1 as months are 0-based
  var year = date.getFullYear() - 2000; // Assuming you want to represen

  var formattedDate = (day > 9 ? day : ('0' + day)) + '.' +  (month > 9 ? month : ('0' + month))  + '.' + year;
  return <span>{formattedDate}</span> }},
  { title: 'R-Punkte', prop: 'points', render : (points) => <span className="points">{points}</span> },
];

const columnsExtended = [ ...columns,
  { title: 'ICW Zertifizierung', prop: 'ICW', render: (ICW) => <span>{ICW ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times"></i>}</span> },
  { title: 'DGfw Zertifizierung', prop: 'DGfw', render: (ICW) => <span>{ICW ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times"></i>}</span>  },
];


const keyMap = {
    VerordnungsfaehigkeitOderRezeptierbarkeit: "Verordnungsfähigkeit oder Rezeptierbarkeit",
    Unterpunkt1: "Unterkategorie 2",
    Unterpunkt2: "Unterkategorie 3",
    IndikationOderErkrankung: "Indikation oder Erkrankung",
    Ernaehrungspumpe: "Ernährungspumpe",
    Nahrungsbehaeltnis: "Nahrungsbehältnis",
}

class SelectableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  };

  deSelectRow = (row) => {
    this.setState({ selected: '' });
  };

  buildRowOptions = (row) => {
    return {
//      onMouseOver: () => this.selectRow(row),
//      onMouseOut: () => this.deSelectRow(row),
      className: this.state.selected === row.id ? 'active-row' : null,
    };
  };

  render() {
    return (
      <DataTable buildRowOptions={this.buildRowOptions} {...this.props} />
    );
  }
}

class Veranstaltung extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      selected: ''
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  }

//  buildRowOptions = (row) => { alert(row.name);
//   return {
//     onClick: this.selectRow.bind(this, row),
//     className: this.state.selected === row.id ? 'active' : null
//   }}

 formatDate = (date) => {
      var date = new Date(date);

      // Extracting day, month, and year components
      var day = date.getDate();
      var month = date.getMonth() + 1; // Adding 1 as months are 0-based
      var year = date.getFullYear() - 2000; // Assuming you want to represen

      var formattedDate = (day > 9 ? day : ('0' + day)) + '.' +  (month > 9 ? month : ('0' + month))  + '.' + year;
      return <span>{formattedDate}</span>
  }

  render() {

  const { veranstaltungenData, match } = this.props;
  const vId = match.params.id;

  const foundEntity = veranstaltungenData.find( v => v.id === +vId );


    return foundEntity ? (
      <>
  <CitySection
  style={{ marginTop: 46 }}
      key={1}
      headline={<h2 style={{ textAlign: 'left', fontWeight: 400, marginBottom: 8 }}>{foundEntity.Produktname}</h2>}
      description={<>
      <div className={"leafContainer leafContainer--produkt"}>
      { (((foundEntity.Produktbild || {}).data || {}).attributes) && <img className={"leafImage leafImage--produkt"} src={API_BASE + foundEntity.Produktbild.data.attributes.url}  />}
      <br/>
        <div className="row produktliste">
            <div className="col-md-6 veranstaltungen">
            <p className="leaf--p">
            <span className="leaf--title">Name</span>
            <span className="leaf--value">{foundEntity.Produktname}</span>
            </p>
            <p className="leaf--p">
            <span className="leaf--title">Kategorie</span>
            <span className="leaf--value">{foundEntity.Hauptkategorie}</span>
            </p>
            <p className="leaf--p">
            <span className="leaf--title">Hersteller</span>
            <span className="leaf--value"><Link to={"/hersteller/" + foundEntity.HerstellerId}>{foundEntity.Hersteller}</Link></span>
            </p>
            </div>
        </div>
        </div>

        { ((foundEntity.Medien || {}).data || []).length > 0 && <MedienCarousel pickedData={foundEntity.Medien.data.map( el => el.attributes)} /> }
        { ((((foundEntity.Datenblaetter || {}).data  || {})[0] || {}).attributes) && <div>
        <Divider orientation="left">Datenblätter</Divider>
                    {((((foundEntity.Datenblaetter || {}).data  || {})[0] || {}).attributes) && <Col span={12}><PdfViewer className={"leafImage leafImage--veranstaltung"} pdf={API_BASE + foundEntity.Datenblaetter.data[0].attributes.url}  /> </Col> }
                    </div> }
        { foundEntity.Produktbeschreibung && <div className="">
                    <Divider orientation="left">Produktbeschreibung</Divider>
                            <div className="edDrop" dangerouslySetInnerHTML={{ __html: foundEntity.Produktbeschreibung }} />

                            </div>}
        { foundEntity.Homepage && <p>
        {foundEntity.Homepage}
        </p>}
                <div style={{ padding: '20px', border: '1px solid #eb008c' }}>
                      <Divider orientation="left">Alle Informationen auf einen Blick</Divider>
                      <Row gutter={[24, 24]}>
                        <Col xs={24} sm={24}>
                          <Typography.Text><strong>Produktname:</strong> {foundEntity.Produktname}</Typography.Text>
                        </Col>
                        <Col xs={24} sm={24}>
                          <Typography.Text><strong>PZN:</strong> {foundEntity.PZN}</Typography.Text>
                        </Col>
                        {foundEntity.Hauptkategorie && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Hauptkategorie:</strong> {foundEntity.Hauptkategorie}</Typography.Text>
                        </Col> }
                        {foundEntity.Unterkategorie && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Unterkategorie:</strong> {foundEntity.Unterkategorie}</Typography.Text>
                        </Col> }
                        {foundEntity.Indikationen && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Indikationen:</strong> {foundEntity.Indikationen}</Typography.Text>
                        </Col> }
                        {foundEntity.Kontraindikationen && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Kontraindikationen:</strong> {foundEntity.Kontraindikationen}</Typography.Text>
                        </Col> }
                        {foundEntity.Anwendungshinweise && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Anwendungshinweise:</strong> {foundEntity.Anwendungshinweise}</Typography.Text>
                        </Col> }
                        {foundEntity.Produktgroesse && <h3 style={{ marginTop: 12, marginBottom: -6 }}> Produktgrößen zur Auswahl </h3>}
                        {foundEntity.Produktgroesse && foundEntity.Produktgroesse.map((groesse, index) => (
                          <Col xs={24} sm={24} key={index}>
                            <Typography.Text><strong>Breite:</strong> {groesse.Breite.substr(1)} cm</Typography.Text>&nbsp;&nbsp;&nbsp;&nbsp;

                            <Typography.Text><strong>Länge:</strong> {groesse.Laenge.substr(1)} cm</Typography.Text>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Typography.Text><strong>PZN:</strong> {groesse.PZN}</Typography.Text>
                          </Col>
                        ))}
                        {foundEntity.Produktgroesse && <Divider />}
                        {foundEntity.VerordnungsfaehigkeitOderRezeptierbarkeit && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Verordnungsfaehigkeit oder Rezeptierbarkeit:</strong> {foundEntity.VerordnungsfaehigkeitOderRezeptierbarkeit ? 'Ja' : 'Nein'}</Typography.Text>
                        </Col> }
                        {foundEntity.Risikoklassifizierung && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Risikoklassifizierung:</strong> {foundEntity.Risikoklassifizierung}</Typography.Text>
                        </Col> }
                        {foundEntity.Wirkstoffhaltig && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Wirkstoffhaltig:</strong> {foundEntity.Wirkstoffhaltig}</Typography.Text>
                        </Col> }
                        {foundEntity.Haftrand && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Haftrand:</strong> {foundEntity.Haftrand}</Typography.Text>
                        </Col> }
                        {foundEntity.Kombination && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Kombination:</strong> {foundEntity.Kombination}</Typography.Text>
                        </Col> }
                        {foundEntity.Unterpunkt1 && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Unterpunkt a)</strong> {foundEntity.Unterpunkt1}</Typography.Text>
                        </Col> }
                        {foundEntity.Unterpunkt2 && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Unterpunkt b)</strong> {foundEntity.Unterpunkt2}</Typography.Text>
                        </Col> }
                        {foundEntity.Energiegehalt && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Energiegehalt:</strong> {foundEntity.Energiegehalt}</Typography.Text>
                        </Col> }
                        {foundEntity.Verabreichungsart && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Verabreichungsart:</strong> {foundEntity.Verabreichungsart}</Typography.Text>
                        </Col> }
                        {foundEntity.Nahrungsbehaeltnis && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Nahrungsbehaeltnis:</strong> {foundEntity.Nahrungsbehaeltnis}</Typography.Text>
                        </Col> }
                        {foundEntity.Ernaehrungspumpe && <Col xs={24} sm={24}>
                          <Typography.Text><strong>Ernaehrungspumpe:</strong> {foundEntity.Ernaehrungspumpe}</Typography.Text>
                        </Col> }
                      </Row>
                    </div>
              </>
        }
    />
    <br />
      </>
    ) : <h2>Produkt nicht gefunden</h2>;
  }
}
//        <Media />
//        <Collections collectionsData={collectionsData} />
const CitySection = ({ style, headline, description, cardsData }) => (
  <div style={style} className="city-section-wrapper">
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      {headline}
    </h2>
    <hr
      style={{
        backgroundColor: '#eb008c',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
      {description}
    </p>
  </div>
);

export default withRouter(Veranstaltung);